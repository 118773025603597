import { lazy } from "react";

// project imports
import AuthLayout from "../layouts/AuthLayout";
import Loadable from "../components/Loadable";

// login routing
const AuthRegister = Loadable(lazy(() => import("../views/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../views/auth/ResetPassword"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../views/auth/ForgotPassword"))
);
const NoPagesFound = Loadable(
  lazy(() => import("../views/dashboard/ErrorStates/NoPagesFound"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "/register",
      element: <AuthRegister />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/update-password",
      element: <ResetPassword />,
    },
    {
      path: "/*",
      element: <NoPagesFound />,
    },
  ],
};

export default AuthenticationRoutes;
