import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const casesSlice = createSlice({
  name: "cases",
  initialState: {
    getAll: { ...initialState, dataDocs: [] },
    getOne: { ...initialState },
    getBase: { ...initialState },
    getStats: { ...initialState },
    getReferrals: { ...initialState },
    getManagedReferrals: { ...initialState },
    getRefusedReferrals: { ...initialState },
    create: { ...initialState },
    updateOne: { ...initialState },
    updateOneReferral: { ...initialState },
    saveOne: { ...initialState },
    saved: { ...initialState },
  },
  reducers: {
    getAllCasesStart(state) {
      state.getAll.data = null;
      state.getAll.dataDocs = state.getAll.dataDocs;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = true;
      state.getAll.success = false;
    },
    getAllCasesSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.dataDocs = state.getAll.dataDocs.concat(
        action?.payload?.cases
      );
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = true;
    },
    getAllCasesFail(state, action) {
      state.getAll.data = null;
      state.getAll.dataDocs = [];
      state.getAll.errorMessage = action.payload;
      state.getAll.error = true;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllCasesReset(state) {
      state.getAll.data = null;
      state.getAll.dataDocs = [];
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneCaseStart(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = true;
      state.getOne.success = false;
    },
    getOneCaseSuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = true;
    },
    getOneCaseFail(state, action) {
      state.getOne.data = null;
      state.getOne.errorMessage = action.payload;
      state.getOne.error = true;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneCaseReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getCaseBaseStageStart(state) {
      state.getBase.data = null;
      state.getBase.error = false;
      state.getBase.errorMessage = null;
      state.getBase.loading = true;
      state.getBase.success = false;
    },
    getCaseBaseStageSuccess(state, action) {
      state.getBase.data = action.payload;
      state.getBase.error = false;
      state.getBase.errorMessage = null;
      state.getBase.loading = false;
      state.getBase.success = true;
    },
    getCaseBaseStageFail(state, action) {
      state.getBase.data = null;
      state.getBase.errorMessage = action.payload;
      state.getBase.error = true;
      state.getBase.loading = false;
      state.getBase.success = false;
    },
    getCaseBaseStageReset(state) {
      state.getBase.data = null;
      state.getBase.error = false;
      state.getBase.errorMessage = null;
      state.getBase.loading = false;
      state.getBase.success = false;
    },
    getCaseStatsStart(state) {
      state.getStats.data = null;
      state.getStats.error = false;
      state.getStats.errorMessage = null;
      state.getStats.loading = true;
      state.getStats.success = false;
    },
    getCaseStatsSuccess(state, action) {
      state.getStats.data = action.payload;
      state.getStats.error = false;
      state.getStats.errorMessage = null;
      state.getStats.loading = false;
      state.getStats.success = true;
    },
    getCaseStatsFail(state, action) {
      state.getStats.data = null;
      state.getStats.errorMessage = action.payload;
      state.getStats.error = true;
      state.getStats.loading = false;
      state.getStats.success = false;
    },
    getCaseStatsReset(state) {
      state.getStats.data = null;
      state.getStats.error = false;
      state.getStats.errorMessage = null;
      state.getStats.loading = false;
      state.getStats.success = false;
    },
    getCaseReferralsStart(state) {
      state.getReferrals.data = null;
      state.getReferrals.error = false;
      state.getReferrals.errorMessage = null;
      state.getReferrals.loading = true;
      state.getReferrals.success = false;
    },
    getCaseReferralsSuccess(state, action) {
      state.getReferrals.data = action.payload;
      state.getReferrals.error = false;
      state.getReferrals.errorMessage = null;
      state.getReferrals.loading = false;
      state.getReferrals.success = true;
    },
    getCaseReferralsFail(state, action) {
      state.getReferrals.data = null;
      state.getReferrals.errorMessage = action.payload;
      state.getReferrals.error = true;
      state.getReferrals.loading = false;
      state.getReferrals.success = false;
    },
    getCaseReferralsReset(state) {
      state.getReferrals.data = null;
      state.getReferrals.error = false;
      state.getReferrals.errorMessage = null;
      state.getReferrals.loading = false;
      state.getReferrals.success = false;
    },
    getCaseManagedReferralsStart(state) {
      state.getManagedReferrals.data = null;
      state.getManagedReferrals.error = false;
      state.getManagedReferrals.errorMessage = null;
      state.getManagedReferrals.loading = true;
      state.getManagedReferrals.success = false;
    },
    getCaseManagedReferralsSuccess(state, action) {
      state.getManagedReferrals.data = action.payload;
      state.getManagedReferrals.error = false;
      state.getManagedReferrals.errorMessage = null;
      state.getManagedReferrals.loading = false;
      state.getManagedReferrals.success = true;
    },
    getCaseManagedReferralsFail(state, action) {
      state.getManagedReferrals.data = null;
      state.getManagedReferrals.errorMessage = action.payload;
      state.getManagedReferrals.error = true;
      state.getManagedReferrals.loading = false;
      state.getManagedReferrals.success = false;
    },
    getCaseManagedReferralsReset(state) {
      state.getManagedReferrals.data = null;
      state.getManagedReferrals.error = false;
      state.getManagedReferrals.errorMessage = null;
      state.getManagedReferrals.loading = false;
      state.getManagedReferrals.success = false;
    },
    getCaseRefusedReferralsStart(state) {
      state.getRefusedReferrals.data = null;
      state.getRefusedReferrals.error = false;
      state.getRefusedReferrals.errorMessage = null;
      state.getRefusedReferrals.loading = true;
      state.getRefusedReferrals.success = false;
    },
    getCaseRefusedReferralsSuccess(state, action) {
      state.getRefusedReferrals.data = action.payload;
      state.getRefusedReferrals.error = false;
      state.getRefusedReferrals.errorMessage = null;
      state.getRefusedReferrals.loading = false;
      state.getRefusedReferrals.success = true;
    },
    getCaseRefusedReferralsFail(state, action) {
      state.getRefusedReferrals.data = null;
      state.getRefusedReferrals.errorMessage = action.payload;
      state.getRefusedReferrals.error = true;
      state.getRefusedReferrals.loading = false;
      state.getRefusedReferrals.success = false;
    },
    getCaseRefusedReferralsReset(state) {
      state.getRefusedReferrals.data = null;
      state.getRefusedReferrals.error = false;
      state.getRefusedReferrals.errorMessage = null;
      state.getRefusedReferrals.loading = false;
      state.getRefusedReferrals.success = false;
    },
    createCaseStart(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = true;
      state.create.success = false;
    },
    createCaseSuccess(state, action) {
      state.create.data = action.payload;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = true;
    },
    createCaseFail(state, action) {
      state.create.data = null;
      state.create.errorMessage = action.payload;
      state.create.error = true;
      state.create.loading = false;
      state.create.success = false;
    },
    createCaseReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateCaseStart(state) {
      state.updateOne.data = null;
      state.updateOne.error = false;
      state.updateOne.errorMessage = null;
      state.updateOne.loading = true;
      state.updateOne.success = false;
    },
    updateCaseSuccess(state, action) {
      state.updateOne.data = action.payload;
      state.updateOne.error = false;
      state.updateOne.errorMessage = null;
      state.updateOne.loading = false;
      state.updateOne.success = true;
    },
    updateCaseFail(state, action) {
      state.updateOne.data = null;
      state.updateOne.errorMessage = action.payload;
      state.updateOne.error = true;
      state.updateOne.loading = false;
      state.updateOne.success = false;
    },
    updateCaseReset(state) {
      state.updateOne.data = null;
      state.updateOne.error = false;
      state.updateOne.errorMessage = null;
      state.updateOne.loading = false;
      state.updateOne.success = false;
    },
    updateCaseReferralStart(state) {
      state.updateOneReferral.data = null;
      state.updateOneReferral.error = false;
      state.updateOneReferral.errorMessage = null;
      state.updateOneReferral.loading = true;
      state.updateOneReferral.success = false;
    },
    updateCaseReferralSuccess(state, action) {
      state.updateOneReferral.data = action.payload;
      state.updateOneReferral.error = false;
      state.updateOneReferral.errorMessage = null;
      state.updateOneReferral.loading = false;
      state.updateOneReferral.success = true;
    },
    updateCaseReferralFail(state, action) {
      state.updateOneReferral.data = null;
      state.updateOneReferral.errorMessage = action.payload;
      state.updateOneReferral.error = true;
      state.updateOneReferral.loading = false;
      state.updateOneReferral.success = false;
    },
    updateCaseReferralReset(state) {
      state.updateOneReferral.data = null;
      state.updateOneReferral.error = false;
      state.updateOneReferral.errorMessage = null;
      state.updateOneReferral.loading = false;
      state.updateOneReferral.success = false;
    },
    saveCaseStart(state) {
      state.saveOne.data = null;
      state.saveOne.error = false;
      state.saveOne.errorMessage = null;
      state.saveOne.loading = true;
      state.saveOne.success = false;
    },
    saveCaseSuccess(state, action) {
      state.saveOne.data = action.payload;
      state.saveOne.error = false;
      state.saveOne.errorMessage = null;
      state.saveOne.loading = false;
      state.saveOne.success = true;
    },
    saveCaseFail(state, action) {
      state.saveOne.data = null;
      state.saveOne.errorMessage = action.payload;
      state.saveOne.error = true;
      state.saveOne.loading = false;
      state.saveOne.success = false;
    },
    saveCaseReset(state) {
      state.saveOne.data = null;
      state.saveOne.error = false;
      state.saveOne.errorMessage = null;
      state.saveOne.loading = false;
      state.saveOne.success = false;
    },
    savedCasesStart(state) {
      state.saved.data = null;
      state.saved.error = false;
      state.saved.errorMessage = null;
      state.saved.loading = true;
      state.saved.success = false;
    },
    savedCasesSuccess(state, action) {
      state.saved.data = action.payload;
      state.saved.error = false;
      state.saved.errorMessage = null;
      state.saved.loading = false;
      state.saved.success = true;
    },
    savedCasesFail(state, action) {
      state.saved.data = null;
      state.saved.errorMessage = action.payload;
      state.saved.error = true;
      state.saved.loading = false;
      state.saved.success = false;
    },
    savedCasesReset(state) {
      state.saved.data = null;
      state.saved.error = false;
      state.saved.errorMessage = null;
      state.saved.loading = false;
      state.saved.success = false;
    },
  },
});
export const getAllCases =
  (
    payload: {
      page: number;
      pageSize: number;
      search?: string;
      sortOrder?: string;
      select?: string;
      role?: string;
      userId?: string;
      find?: string;
      createdAt?: Date;
      searchByUser?: string;
      startDateAt?: Date;
      endDateAt?: Date;
      isActive?: boolean;
      isPublished?: boolean;
      searchByCountryIds?: string;
      searchByRegionIds?: string;
      searchByClaimTypeIds?: string;
      searchByCaseTypeIds?: string;
      searchByForumIds?: string;
      investmentAmountMin?: number;
      investmentAmountMax?: number;
      showByGroup?: string;
      viewCasesAsPublishedStatus?: string;
      isNewCase?: boolean;
      isArchived?: boolean;
      stageNum?: number;
      getNewCases?: boolean;
      status?: string;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllCasesStart());
      const {
        page,
        pageSize,
        search,
        select,
        sortOrder,
        role,
        userId,
        find,
        searchByUser,
        startDateAt,
        endDateAt,
        isActive,
        isPublished,
        searchByCountryIds,
        searchByRegionIds,
        searchByClaimTypeIds,
        searchByCaseTypeIds,
        searchByForumIds,
        investmentAmountMin,
        investmentAmountMax,
        showByGroup,
        viewCasesAsPublishedStatus,
        isNewCase,
        isArchived,
        stageNum,
        getNewCases,
        status,
      } = payload;
      const response = await APIClient.get("/cases", {
        params: {
          page: page,
          pageSize: pageSize,
          search,
          select,
          sortOrder,
          role,
          userId,
          find,
          searchByUser,
          startDateAt,
          endDateAt,
          isActive,
          isPublished,
          searchByCountryIds,
          searchByRegionIds,
          searchByClaimTypeIds,
          searchByCaseTypeIds,
          searchByForumIds,
          investmentAmountMin,
          investmentAmountMax,
          showByGroup,
          viewCasesAsPublishedStatus,
          isNewCase,
          isArchived,
          stageNum,
          getNewCases,
          status,
        },
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllCasesSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllCasesFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllCasesFail, language);
    }
  };
export const clearCases = () => async (dispatch: AppDispatch) => {
  dispatch(getAllCasesReset());
};
export const getOneCase =
  (
    payload: {
      id: string;
      stages?: string;
      initialDocs?: boolean;
      fullDocs?: boolean;
    },
    options: { language: string; baseStage?: boolean }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, baseStage } = options;
    try {
      dispatch(getOneCaseStart());
      if (baseStage) dispatch(getCaseBaseStageStart());

      const { id, stages, initialDocs, fullDocs } = payload;
      const response = await APIClient.get(`/cases/${id}`, {
        params: { stages, initialDocs, fullDocs },
      });
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getOneCaseSuccess(data));
        if (baseStage) dispatch(getCaseBaseStageSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneCaseFail(response.data.errors));
        if (baseStage) dispatch(getCaseBaseStageFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneCaseFail, language);
      if (baseStage) dispatch(getCaseBaseStageFail("Something went wrong!"));
    }
  };
export const clearOneCase = () => async (dispatch: AppDispatch) => {
  dispatch(getOneCaseReset());
  dispatch(getCaseBaseStageReset());
};
export const getCaseStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getCaseStatsStart());

      const response = await APIClient.get("/cases/stats");
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getCaseStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getCaseStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getCaseStatsFail, language);
    }
  };
export const clearCaseStats = () => async (dispatch: AppDispatch) => {
  dispatch(getCaseStatsReset());
};
export const getCaseReferrals =
  (
    payload: {
      page: number;
      pageSize: number;
      search?: string;
      sortOrder?: string;
      sortField?: string;
      searchByUser?: string;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getCaseReferralsStart());

      const { page, pageSize, search, sortField, sortOrder, searchByUser } =
        payload;
      const response = await APIClient.get("/cases/referals", {
        params: { page, pageSize, search, sortField, sortOrder, searchByUser },
      });
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getCaseReferralsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getCaseReferralsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getCaseReferralsFail, language);
    }
  };
export const clearCaseReferrals = () => async (dispatch: AppDispatch) => {
  dispatch(getCaseReferralsReset());
};
export const getCaseManagedReferrals =
  (
    payload: {
      page: number;
      pageSize: number;
      search?: string;
      sortOrder?: string;
      sortField?: string;
      searchByUser?: string;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getCaseManagedReferralsStart());

      const { page, pageSize, search, sortField, sortOrder, searchByUser } =
        payload;
      const response = await APIClient.get("/cases/managed", {
        params: { page, pageSize, search, sortField, sortOrder, searchByUser },
      });
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getCaseManagedReferralsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getCaseManagedReferralsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getCaseManagedReferralsFail, language);
    }
  };
export const clearCaseManagedReferrals =
  () => async (dispatch: AppDispatch) => {
    dispatch(getCaseManagedReferralsReset());
  };
export const getCaseRefusedReferrals =
  (
    payload: {
      page: number;
      pageSize: number;
      search?: string;
      sortOrder?: string;
      sortField?: string;
      searchByUser?: string;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getCaseRefusedReferralsStart());

      const { page, pageSize, search, sortField, sortOrder, searchByUser } =
        payload;
      const response = await APIClient.get("/cases/refused", {
        params: { page, pageSize, search, sortField, sortOrder, searchByUser },
      });
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getCaseRefusedReferralsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getCaseRefusedReferralsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getCaseRefusedReferralsFail, language);
    }
  };
export const clearCaseRefusedReferrals =
  () => async (dispatch: AppDispatch) => {
    dispatch(getCaseRefusedReferralsReset());
  };
export const createCase =
  (
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      step?: number;
      handleStep?: (step: number) => void;
      updateState?: (value: string) => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      step,
      handleStep,
      updateState,
    } = options;
    try {
      dispatch(createCaseStart());

      const response = await APIClient.post(`/cases`, payload);
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(createCaseSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (step && handleStep) handleStep(step);
        if (updateState) updateState(data.id);
      } else if (response && response.data && response.data.errors) {
        dispatch(createCaseFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createCaseFail, language);
    }
  };
export const clearCreateCase = () => async (dispatch: AppDispatch) => {
  dispatch(createCaseReset());
};
export const updateCase =
  (
    id: string,
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      step?: number;
      handleStep?: (step: number) => void;
      shouldNavigate?: boolean;
      pathname?: string;
      navigate?: (path: string) => void;
      isWaiting?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      step,
      handleStep,
      shouldNavigate,
      pathname,
      navigate,
      isWaiting,
    } = options;
    try {
      dispatch(updateCaseStart());

      const response = await APIClient.patch(`/cases/${id}`, payload);
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(updateCaseSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (shouldNavigate && pathname && navigate) navigate(pathname);
        if (step && handleStep) handleStep(step);
        if (isWaiting) isWaiting();
      } else if (response && response.data && response.data.errors) {
        dispatch(updateCaseFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateCaseFail, language);
    }
  };
export const clearUpdateCase = () => async (dispatch: AppDispatch) => {
  dispatch(updateCaseReset());
};
export const updateCaseReferral =
  (
    payload: { caseId: string; action: string },
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      recall?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, onSuccessMessage, showToast, recall } = options;
    try {
      dispatch(updateCaseReferralStart());

      const response = await APIClient.patch(`/cases/referals`, payload);
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(updateCaseReferralSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (recall) recall();
      } else if (response && response.data && response.data.errors) {
        dispatch(updateCaseReferralFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateCaseReferralFail, language);
    }
  };
export const clearUpdateCaseReferral = () => async (dispatch: AppDispatch) => {
  dispatch(updateCaseReferralReset());
};
export const saveCase =
  (
    payload: { id: string; saveCase: boolean },
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      recall?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, onSuccessMessage, showToast, recall } = options;
    try {
      dispatch(saveCaseStart());

      const { id, saveCase } = payload;
      const response = await APIClient.patch(`/cases/${id}/save-case`, {
        saveCase,
      });
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(saveCaseSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (recall) recall();
      } else if (response && response.data && response.data.errors) {
        dispatch(saveCaseFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, saveCaseFail, language);
    }
  };
export const clearSaveCase = () => async (dispatch: AppDispatch) => {
  dispatch(saveCaseReset());
};
export const savedCases =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(savedCasesStart());

      const response = await APIClient.get(`/cases/saved-cases`);
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(savedCasesSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(savedCasesFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, savedCasesFail, language);
    }
  };
export const clearSavedCases = () => async (dispatch: AppDispatch) => {
  dispatch(savedCasesReset());
};

const {
  getAllCasesFail,
  getAllCasesStart,
  getAllCasesSuccess,
  getAllCasesReset,
  getOneCaseFail,
  getOneCaseStart,
  getOneCaseSuccess,
  getOneCaseReset,
  getCaseBaseStageFail,
  getCaseBaseStageStart,
  getCaseBaseStageSuccess,
  getCaseBaseStageReset,
  getCaseStatsFail,
  getCaseStatsStart,
  getCaseStatsSuccess,
  getCaseStatsReset,
  getCaseReferralsFail,
  getCaseReferralsStart,
  getCaseReferralsSuccess,
  getCaseReferralsReset,
  getCaseManagedReferralsFail,
  getCaseManagedReferralsStart,
  getCaseManagedReferralsSuccess,
  getCaseManagedReferralsReset,
  getCaseRefusedReferralsFail,
  getCaseRefusedReferralsStart,
  getCaseRefusedReferralsSuccess,
  getCaseRefusedReferralsReset,
  createCaseFail,
  createCaseStart,
  createCaseSuccess,
  createCaseReset,
  updateCaseFail,
  updateCaseStart,
  updateCaseSuccess,
  updateCaseReset,
  updateCaseReferralFail,
  updateCaseReferralStart,
  updateCaseReferralSuccess,
  updateCaseReferralReset,
  saveCaseFail,
  saveCaseStart,
  saveCaseSuccess,
  saveCaseReset,
  savedCasesFail,
  savedCasesStart,
  savedCasesSuccess,
  savedCasesReset,
} = casesSlice.actions;
export default casesSlice.reducer;
