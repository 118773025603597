import Routes from "./routes";
import "antd/dist/reset.css";
import "./i18n/config";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import { ltr, rtl } from "./types";

function App() {
  const direction = useSelector((state: RootState) => state.language.direction);

  return (
    <ConfigProvider direction={direction === ltr ? ltr : rtl}>
      <Routes />
    </ConfigProvider>
  );
}

export default App;
