import { AppDispatch } from "../store";
import { AR, EN } from "../types";
import { Notification } from "./Notification";

export const HandleAPIError = (
  error: any,
  dispatch: AppDispatch,
  action: any,
  language: string
) => {
  if (
    error?.response?.data?.errors &&
    typeof error.response.data.errors === "string"
  ) {
    dispatch(action(error.response.data.errors));
    Notification("error", error.response.data.errors);
  } else if (
    error &&
    error.response?.data &&
    error.response.data.message &&
    typeof error.response.data.message === "string"
  ) {
    dispatch(action(error.response.data.message));
    Notification("error", error.response.data.message);
  } else if (
    Object.prototype.toString.call(error.response.data.errors) ===
    "[object Object]"
  ) {
    const messagesArray: string[] = [];
    Object.keys(error.response.data.errors).map((key) => {
      if (language === EN && key === language && key === EN) {
        messagesArray.push(error.response.data.errors[language]);
      } else if (language === AR && key === language && key === AR) {
        messagesArray.push(error.response.data.errors[language]);
      } else if (key !== EN && key !== AR)
        messagesArray.push(error.response.data.errors[key]);
    });
    const message = messagesArray.join(".\n");
    dispatch(action(message));
    Notification("error", message);
  } else {
    dispatch(action(error.message));
    Notification("error", error.message);
  }
};
