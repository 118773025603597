import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const defaultLimit = 10;
const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    getNetworkStats: { ...initialState },
    getInvestorStats: { ...initialState },
    getClaimTypeStats: { ...initialState },
    getTotalCasesStats: { ...initialState },
    getCasesByInvestorTypeStats: { ...initialState },
    getCasesForLexolentStats: { ...initialState },
    getCasesForHousefundStats: { ...initialState },
    getCasesForMarketplaceStats: { ...initialState },
    getActiveInvestor: { ...initialState },
    getMemberMostCases: { ...initialState },
    getNewCasesStats: { ...initialState },
  },
  reducers: {
    getCaseNetworkStatsStart(state) {
      state.getNetworkStats.data = null;
      state.getNetworkStats.error = false;
      state.getNetworkStats.errorMessage = null;
      state.getNetworkStats.loading = true;
      state.getNetworkStats.success = false;
    },
    getCaseNetworkStatsSuccess(state, action) {
      state.getNetworkStats.data = action.payload;
      state.getNetworkStats.error = false;
      state.getNetworkStats.errorMessage = null;
      state.getNetworkStats.loading = false;
      state.getNetworkStats.success = true;
    },
    getCaseNetworkStatsFail(state, action) {
      state.getNetworkStats.data = null;
      state.getNetworkStats.errorMessage = action.payload;
      state.getNetworkStats.error = true;
      state.getNetworkStats.loading = false;
      state.getNetworkStats.success = false;
    },
    getCaseNetworkStatsReset(state) {
      state.getNetworkStats.data = null;
      state.getNetworkStats.error = false;
      state.getNetworkStats.errorMessage = null;
      state.getNetworkStats.loading = false;
      state.getNetworkStats.success = false;
    },
    getInvestorStatsStart(state) {
      state.getInvestorStats.data = null;
      state.getInvestorStats.error = false;
      state.getInvestorStats.errorMessage = null;
      state.getInvestorStats.loading = true;
      state.getInvestorStats.success = false;
    },
    getInvestorStatsSuccess(state, action) {
      state.getInvestorStats.data = action.payload;
      state.getInvestorStats.error = false;
      state.getInvestorStats.errorMessage = null;
      state.getInvestorStats.loading = false;
      state.getInvestorStats.success = true;
    },
    getInvestorStatsFail(state, action) {
      state.getInvestorStats.data = null;
      state.getInvestorStats.errorMessage = action.payload;
      state.getInvestorStats.error = true;
      state.getInvestorStats.loading = false;
      state.getInvestorStats.success = false;
    },
    getInvestorStatsReset(state) {
      state.getInvestorStats.data = null;
      state.getInvestorStats.error = false;
      state.getInvestorStats.errorMessage = null;
      state.getInvestorStats.loading = false;
      state.getInvestorStats.success = false;
    },
    getClaimTypeStatsStart(state) {
      state.getClaimTypeStats.data = null;
      state.getClaimTypeStats.error = false;
      state.getClaimTypeStats.errorMessage = null;
      state.getClaimTypeStats.loading = true;
      state.getClaimTypeStats.success = false;
    },
    getClaimTypeStatsSuccess(state, action) {
      state.getClaimTypeStats.data = action.payload;
      state.getClaimTypeStats.error = false;
      state.getClaimTypeStats.errorMessage = null;
      state.getClaimTypeStats.loading = false;
      state.getClaimTypeStats.success = true;
    },
    getClaimTypeStatsFail(state, action) {
      state.getClaimTypeStats.data = null;
      state.getClaimTypeStats.errorMessage = action.payload;
      state.getClaimTypeStats.error = true;
      state.getClaimTypeStats.loading = false;
      state.getClaimTypeStats.success = false;
    },
    getClaimTypeStatsReset(state) {
      state.getClaimTypeStats.data = null;
      state.getClaimTypeStats.error = false;
      state.getClaimTypeStats.errorMessage = null;
      state.getClaimTypeStats.loading = false;
      state.getClaimTypeStats.success = false;
    },
    getTotalCasesStatsStart(state) {
      state.getTotalCasesStats.data = null;
      state.getTotalCasesStats.error = false;
      state.getTotalCasesStats.errorMessage = null;
      state.getTotalCasesStats.loading = true;
      state.getTotalCasesStats.success = false;
    },
    getTotalCasesStatsSuccess(state, action) {
      state.getTotalCasesStats.data = action.payload;
      state.getTotalCasesStats.error = false;
      state.getTotalCasesStats.errorMessage = null;
      state.getTotalCasesStats.loading = false;
      state.getTotalCasesStats.success = true;
    },
    getTotalCasesStatsFail(state, action) {
      state.getTotalCasesStats.data = null;
      state.getTotalCasesStats.errorMessage = action.payload;
      state.getTotalCasesStats.error = true;
      state.getTotalCasesStats.loading = false;
      state.getTotalCasesStats.success = false;
    },
    getTotalCasesStatsReset(state) {
      state.getTotalCasesStats.data = null;
      state.getTotalCasesStats.error = false;
      state.getTotalCasesStats.errorMessage = null;
      state.getTotalCasesStats.loading = false;
      state.getTotalCasesStats.success = false;
    },
    getCasesByInvestorTypeStatsStart(state) {
      state.getCasesByInvestorTypeStats.data = null;
      state.getCasesByInvestorTypeStats.error = false;
      state.getCasesByInvestorTypeStats.errorMessage = null;
      state.getCasesByInvestorTypeStats.loading = true;
      state.getCasesByInvestorTypeStats.success = false;
    },
    getCasesByInvestorTypeStatsSuccess(state, action) {
      state.getCasesByInvestorTypeStats.data = action.payload;
      state.getCasesByInvestorTypeStats.error = false;
      state.getCasesByInvestorTypeStats.errorMessage = null;
      state.getCasesByInvestorTypeStats.loading = false;
      state.getCasesByInvestorTypeStats.success = true;
    },
    getCasesByInvestorTypeStatsFail(state, action) {
      state.getCasesByInvestorTypeStats.data = null;
      state.getCasesByInvestorTypeStats.errorMessage = action.payload;
      state.getCasesByInvestorTypeStats.error = true;
      state.getCasesByInvestorTypeStats.loading = false;
      state.getCasesByInvestorTypeStats.success = false;
    },
    getCasesByInvestorTypeStatsReset(state) {
      state.getCasesByInvestorTypeStats.data = null;
      state.getCasesByInvestorTypeStats.error = false;
      state.getCasesByInvestorTypeStats.errorMessage = null;
      state.getCasesByInvestorTypeStats.loading = false;
      state.getCasesByInvestorTypeStats.success = false;
    },
    getCasesForLexolentStatsStart(state) {
      state.getCasesForLexolentStats.data = null;
      state.getCasesForLexolentStats.error = false;
      state.getCasesForLexolentStats.errorMessage = null;
      state.getCasesForLexolentStats.loading = true;
      state.getCasesForLexolentStats.success = false;
    },
    getCasesForLexolentStatsSuccess(state, action) {
      state.getCasesForLexolentStats.data = action.payload;
      state.getCasesForLexolentStats.error = false;
      state.getCasesForLexolentStats.errorMessage = null;
      state.getCasesForLexolentStats.loading = false;
      state.getCasesForLexolentStats.success = true;
    },
    getCasesForLexolentStatsFail(state, action) {
      state.getCasesForLexolentStats.data = null;
      state.getCasesForLexolentStats.errorMessage = action.payload;
      state.getCasesForLexolentStats.error = true;
      state.getCasesForLexolentStats.loading = false;
      state.getCasesForLexolentStats.success = false;
    },
    getCasesForLexolentStatsReset(state) {
      state.getCasesForLexolentStats.data = null;
      state.getCasesForLexolentStats.error = false;
      state.getCasesForLexolentStats.errorMessage = null;
      state.getCasesForLexolentStats.loading = false;
      state.getCasesForLexolentStats.success = false;
    },
    getCasesForHousefundStatsStart(state) {
      state.getCasesForHousefundStats.data = null;
      state.getCasesForHousefundStats.error = false;
      state.getCasesForHousefundStats.errorMessage = null;
      state.getCasesForHousefundStats.loading = true;
      state.getCasesForHousefundStats.success = false;
    },
    getCasesForHousefundStatsSuccess(state, action) {
      state.getCasesForHousefundStats.data = action.payload;
      state.getCasesForHousefundStats.error = false;
      state.getCasesForHousefundStats.errorMessage = null;
      state.getCasesForHousefundStats.loading = false;
      state.getCasesForHousefundStats.success = true;
    },
    getCasesForHousefundStatsFail(state, action) {
      state.getCasesForHousefundStats.data = null;
      state.getCasesForHousefundStats.errorMessage = action.payload;
      state.getCasesForHousefundStats.error = true;
      state.getCasesForHousefundStats.loading = false;
      state.getCasesForHousefundStats.success = false;
    },
    getCasesForHousefundStatsReset(state) {
      state.getCasesForHousefundStats.data = null;
      state.getCasesForHousefundStats.error = false;
      state.getCasesForHousefundStats.errorMessage = null;
      state.getCasesForHousefundStats.loading = false;
      state.getCasesForHousefundStats.success = false;
    },
    getCasesForMarketplaceStatsStart(state) {
      state.getCasesForMarketplaceStats.data = null;
      state.getCasesForMarketplaceStats.error = false;
      state.getCasesForMarketplaceStats.errorMessage = null;
      state.getCasesForMarketplaceStats.loading = true;
      state.getCasesForMarketplaceStats.success = false;
    },
    getCasesForMarketplaceStatsSuccess(state, action) {
      state.getCasesForMarketplaceStats.data = action.payload;
      state.getCasesForMarketplaceStats.error = false;
      state.getCasesForMarketplaceStats.errorMessage = null;
      state.getCasesForMarketplaceStats.loading = false;
      state.getCasesForMarketplaceStats.success = true;
    },
    getCasesForMarketplaceStatsFail(state, action) {
      state.getCasesForMarketplaceStats.data = null;
      state.getCasesForMarketplaceStats.errorMessage = action.payload;
      state.getCasesForMarketplaceStats.error = true;
      state.getCasesForMarketplaceStats.loading = false;
      state.getCasesForMarketplaceStats.success = false;
    },
    getCasesForMarketplaceStatsReset(state) {
      state.getCasesForMarketplaceStats.data = null;
      state.getCasesForMarketplaceStats.error = false;
      state.getCasesForMarketplaceStats.errorMessage = null;
      state.getCasesForMarketplaceStats.loading = false;
      state.getCasesForMarketplaceStats.success = false;
    },
    getActiveInvestorStart(state) {
      state.getActiveInvestor.data = null;
      state.getActiveInvestor.error = false;
      state.getActiveInvestor.errorMessage = null;
      state.getActiveInvestor.loading = true;
      state.getActiveInvestor.success = false;
    },
    getActiveInvestorSuccess(state, action) {
      state.getActiveInvestor.data = action.payload;
      state.getActiveInvestor.error = false;
      state.getActiveInvestor.errorMessage = null;
      state.getActiveInvestor.loading = false;
      state.getActiveInvestor.success = true;
    },
    getActiveInvestorFail(state, action) {
      state.getActiveInvestor.data = null;
      state.getActiveInvestor.errorMessage = action.payload;
      state.getActiveInvestor.error = true;
      state.getActiveInvestor.loading = false;
      state.getActiveInvestor.success = false;
    },
    getActiveInvestorReset(state) {
      state.getActiveInvestor.data = null;
      state.getActiveInvestor.error = false;
      state.getActiveInvestor.errorMessage = null;
      state.getActiveInvestor.loading = false;
      state.getActiveInvestor.success = false;
    },
    getMemberMostCasesStart(state) {
      state.getMemberMostCases.data = null;
      state.getMemberMostCases.error = false;
      state.getMemberMostCases.errorMessage = null;
      state.getMemberMostCases.loading = true;
      state.getMemberMostCases.success = false;
    },
    getMemberMostCasesSuccess(state, action) {
      state.getMemberMostCases.data = action.payload;
      state.getMemberMostCases.error = false;
      state.getMemberMostCases.errorMessage = null;
      state.getMemberMostCases.loading = false;
      state.getMemberMostCases.success = true;
    },
    getMemberMostCasesFail(state, action) {
      state.getMemberMostCases.data = null;
      state.getMemberMostCases.errorMessage = action.payload;
      state.getMemberMostCases.error = true;
      state.getMemberMostCases.loading = false;
      state.getMemberMostCases.success = false;
    },
    getMemberMostCasesReset(state) {
      state.getMemberMostCases.data = null;
      state.getMemberMostCases.error = false;
      state.getMemberMostCases.errorMessage = null;
      state.getMemberMostCases.loading = false;
      state.getMemberMostCases.success = false;
    },
    getNewCasesStatsStart(state) {
      state.getNewCasesStats.data = null;
      state.getNewCasesStats.error = false;
      state.getNewCasesStats.errorMessage = null;
      state.getNewCasesStats.loading = true;
      state.getNewCasesStats.success = false;
    },
    getNewCasesStatsSuccess(state, action) {
      state.getNewCasesStats.data = action.payload;
      state.getNewCasesStats.error = false;
      state.getNewCasesStats.errorMessage = null;
      state.getNewCasesStats.loading = false;
      state.getNewCasesStats.success = true;
    },
    getNewCasesStatsFail(state, action) {
      state.getNewCasesStats.data = null;
      state.getNewCasesStats.errorMessage = action.payload;
      state.getNewCasesStats.error = true;
      state.getNewCasesStats.loading = false;
      state.getNewCasesStats.success = false;
    },
    getNewCasesStatsReset(state) {
      state.getNewCasesStats.data = null;
      state.getNewCasesStats.error = false;
      state.getNewCasesStats.errorMessage = null;
      state.getNewCasesStats.loading = false;
      state.getNewCasesStats.success = false;
    },
  },
});
export const getCaseNetworkStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getCaseNetworkStatsStart());

      const response = await APIClient.get("/dashboard/member/cases/stats");
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getCaseNetworkStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getCaseNetworkStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getCaseNetworkStatsFail, language);
    }
  };
export const clearCaseNetworkStats = () => async (dispatch: AppDispatch) => {
  dispatch(getCaseNetworkStatsReset());
};
export const getInvestorStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getInvestorStatsStart());

      const response = await APIClient.get("/dashboard/investor-bid-stats");
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getInvestorStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getInvestorStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getInvestorStatsFail, language);
    }
  };
export const clearInvestorStats = () => async (dispatch: AppDispatch) => {
  dispatch(getInvestorStatsReset());
};
export const getClaimTypeStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getClaimTypeStatsStart());

      const response = await APIClient.get(
        "/dashboard/network-member/claimType/stats"
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getClaimTypeStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getClaimTypeStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getClaimTypeStatsFail, language);
    }
  };
export const clearClaimTypeStats = () => async (dispatch: AppDispatch) => {
  dispatch(getClaimTypeStatsReset());
};
export const getTotalCasesStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getTotalCasesStatsStart());

      const response = await APIClient.get("/dashboard/total-cases");
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getTotalCasesStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getTotalCasesStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getTotalCasesStatsFail, language);
    }
  };
export const clearTotalCasesStats = () => async (dispatch: AppDispatch) => {
  dispatch(getTotalCasesStatsReset());
};
export const getCasesByInvestorTypeStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getCasesByInvestorTypeStatsStart());

      const response = await APIClient.get(
        "/dashboard/total-cases-by-investor-type"
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getCasesByInvestorTypeStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getCasesByInvestorTypeStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(
        error,
        dispatch,
        getCasesByInvestorTypeStatsFail,
        language
      );
    }
  };
export const clearCasesByInvestorTypeStats =
  () => async (dispatch: AppDispatch) => {
    dispatch(getCasesByInvestorTypeStatsReset());
  };
export const getCasesForLexolentStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getCasesForLexolentStatsStart());

      const response = await APIClient.get(
        "/dashboard/total-cases-for-lexolent"
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getCasesForLexolentStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getCasesForLexolentStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getCasesForLexolentStatsFail, language);
    }
  };
export const clearCasesForLexolentStats =
  () => async (dispatch: AppDispatch) => {
    dispatch(getCasesForLexolentStatsReset());
  };
export const getCasesForHousefundStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getCasesForHousefundStatsStart());

      const response = await APIClient.get(
        "/dashboard/total-cases-for-housefund"
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getCasesForHousefundStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getCasesForHousefundStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getCasesForHousefundStatsFail, language);
    }
  };
export const clearCasesForHousefundStats =
  () => async (dispatch: AppDispatch) => {
    dispatch(getCasesForHousefundStatsReset());
  };
export const getCasesForMarketplaceStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getCasesForMarketplaceStatsStart());

      const response = await APIClient.get(
        "/dashboard/total-cases-for-marketplace"
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getCasesForMarketplaceStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getCasesForMarketplaceStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(
        error,
        dispatch,
        getCasesForMarketplaceStatsFail,
        language
      );
    }
  };
export const clearCasesForMarketplaceStats =
  () => async (dispatch: AppDispatch) => {
    dispatch(getCasesForMarketplaceStatsReset());
  };
export const getActiveInvestor =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getActiveInvestorStart());

      const response = await APIClient.get(
        "/dashboard/get-most-active-investor",
        { params: { limit: defaultLimit } }
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getActiveInvestorSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getActiveInvestorFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getActiveInvestorFail, language);
    }
  };
export const clearActiveInvestor = () => async (dispatch: AppDispatch) => {
  dispatch(getActiveInvestorReset());
};
export const getMemberMostCases =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getMemberMostCasesStart());

      const response = await APIClient.get(
        "/dashboard/get-network-members-most-cases",
        { params: { limit: defaultLimit } }
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getMemberMostCasesSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getMemberMostCasesFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getMemberMostCasesFail, language);
    }
  };
export const clearMemberMostCases = () => async (dispatch: AppDispatch) => {
  dispatch(getMemberMostCasesReset());
};
export const getNewCasesStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getNewCasesStatsStart());

      const response = await APIClient.get("/dashboard/get-new-cases");
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getNewCasesStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getNewCasesStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getNewCasesStatsFail, language);
    }
  };
export const clearNewCasesStats = () => async (dispatch: AppDispatch) => {
  dispatch(getNewCasesStatsReset());
};

const {
  getCaseNetworkStatsFail,
  getCaseNetworkStatsStart,
  getCaseNetworkStatsSuccess,
  getCaseNetworkStatsReset,
  getInvestorStatsFail,
  getInvestorStatsStart,
  getInvestorStatsSuccess,
  getInvestorStatsReset,
  getClaimTypeStatsFail,
  getClaimTypeStatsStart,
  getClaimTypeStatsSuccess,
  getClaimTypeStatsReset,
  getTotalCasesStatsFail,
  getTotalCasesStatsStart,
  getTotalCasesStatsSuccess,
  getTotalCasesStatsReset,
  getCasesByInvestorTypeStatsFail,
  getCasesByInvestorTypeStatsStart,
  getCasesByInvestorTypeStatsSuccess,
  getCasesByInvestorTypeStatsReset,
  getCasesForLexolentStatsFail,
  getCasesForLexolentStatsStart,
  getCasesForLexolentStatsSuccess,
  getCasesForLexolentStatsReset,
  getCasesForHousefundStatsFail,
  getCasesForHousefundStatsStart,
  getCasesForHousefundStatsSuccess,
  getCasesForHousefundStatsReset,
  getCasesForMarketplaceStatsFail,
  getCasesForMarketplaceStatsStart,
  getCasesForMarketplaceStatsSuccess,
  getCasesForMarketplaceStatsReset,
  getActiveInvestorFail,
  getActiveInvestorStart,
  getActiveInvestorSuccess,
  getActiveInvestorReset,
  getMemberMostCasesFail,
  getMemberMostCasesStart,
  getMemberMostCasesSuccess,
  getMemberMostCasesReset,
  getNewCasesStatsFail,
  getNewCasesStatsStart,
  getNewCasesStatsSuccess,
  getNewCasesStatsReset,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
