import { lazy } from "react";

// project imports
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import AuthGuard from "../utils/route-guard/AuthGuard";

const NetworkMemberDashboard = Loadable(
  lazy(() => import("../views/dashboard/Users/NetworkMember"))
);
const FeaturedMembers = Loadable(
  lazy(() => import("../views/dashboard/FeaturedMembers"))
);
const EditProfile = Loadable(
  lazy(() => import("../views/dashboard/Edit-Profile"))
);
const Cases = Loadable(
  lazy(() => import("../views/dashboard/Cases/NetworkMember"))
);
const CasesReferred = Loadable(
  lazy(() => import("../views/dashboard/CasesReferred/index"))
);
const CreateCases = Loadable(
  lazy(() => import("../views/dashboard/Cases/Create"))
);
const FAQ = Loadable(lazy(() => import("../components/FAQ")));
const Managment = Loadable(
  lazy(() => import("../views/dashboard/CaseBidsManagment"))
);
const LexConnect = Loadable(
  lazy(() => import("../views/dashboard/LexConnect"))
);
const EstimatedFee = Loadable(
  lazy(() => import("../views/dashboard/EstimatedFee"))
);

// ==============================|| USER ROUTING ||============================== //

const NetworkMemberRoutes = {
  path: "/dashboard",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <NetworkMemberDashboard />,
    },
    {
      path: "/dashboard/edit-profile",
      element: <EditProfile />,
    },
    {
      path: "/dashboard/featured-members",
      element: <FeaturedMembers />,
    },
    {
      path: "/dashboard/cases",
      element: <Cases />,
    },
    {
      path: "/dashboard/cases-referred",
      element: <CasesReferred />,
    },
    {
      path: "/dashboard/cases/create",
      element: <CreateCases />,
    },
    {
      path: "/dashboard/cases/edit/:id",
      element: <CreateCases />,
    },
    {
      path: "/dashboard/faq",
      element: <FAQ />,
    },
    {
      path: "/dashboard/case-bids/management/:id",
      element: <Managment />,
    },
    {
      path: "/dashboard/lexconnect",
      element: <LexConnect />,
    },
    {
      path: "/dashboard/estimated-fee",
      element: <EstimatedFee />,
    },
  ],
};

export default NetworkMemberRoutes;
