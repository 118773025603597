import { Layout } from "antd";
import "../../style/lexhublayout.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { CustomizationButton } from "../../components/ui/buttons/Customization";
import { HeaderSection } from "./Header";
import { FooterSection } from "./Footer";
import { useEffect, useState } from "react";
import { MobileMenu } from "../../components/ui/menu/Mobile";
import { clearMe, getMe } from "../../store/me";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { NavTypes, Roles } from "../../types";

const { Content } = Layout;
interface Nav {
  key: string;
  title: string;
  path: string;
  comingSoon: boolean;
}
const nav: Nav[] = [
  {
    key: NavTypes.LEXHUB,
    title: "LexHub",
    path: "/lexhub",
    comingSoon: false,
  },
  {
    key: NavTypes.LEXINVEST,
    title: "LexInvest",
    path: "/dashboard",
    comingSoon: false,
  },
  {
    key: NavTypes.LEXTRADE,
    title: "LexTrade",
    path: "/dashboard",
    comingSoon: true,
  },
  {
    key: NavTypes.LEXWELLNES,
    title: "LexWellness",
    path: "/dashboard",
    comingSoon: true,
  },
];
type ContextType = { activeNav: string; setActiveNav: (value: string) => void };
const LexhubLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeNav, setActiveNav] = useState<string>(NavTypes.LEXHUB);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const currentUser = useSelector((state: RootState) => state.me.getMe);
  const language = useSelector((state: RootState) => state.language.language);

  useEffect(() => {
    return () => {
      dispatch(clearMe());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMe({ language }));
  }, [dispatch]);

  const handleClick = (nav: Nav) => {
    setActiveNav(nav.key);
    setIsMenuOpen(false);
    if (!nav.comingSoon && nav.key === NavTypes.LEXINVEST) {
      if (currentUser?.data?.role === Roles.INVESTOR_MEMBER)
        navigate("/dashboard");
      else navigate("/dashboard/cases");
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return currentUser?.loading ? (
    <Loader />
  ) : (
    <Layout className="lexhub">
      <HeaderSection
        isMenuOpen={isMenuOpen}
        nav={nav}
        handleClick={handleClick}
        toggleMenu={toggleMenu}
      />
      <Layout>
        <Content className="lexhub-content">
          <Outlet context={{ activeNav, setActiveNav } satisfies ContextType} />
          <CustomizationButton />
        </Content>
      </Layout>
      <FooterSection />
      <MobileMenu isMenuOpen={isMenuOpen} nav={nav} handleClick={handleClick} />
    </Layout>
  );
};
export default LexhubLayout;
