export const EN = "en";
export const AR = "ar";

export const ltr = "ltr";
export const rtl = "rtl";

export const CHECK_CONFLICT = "CHECK_CONFLICT";
export const PASSED_CONFLICT = "PASSED_CONFLICT";
export const UN_PASSED_CONFLICT = "UN_PASSED_CONFLICT";
export const PRIOR_KNOWLEDGE = "PRIOR_KNOWLEDGE";
export const MAKE_BID = "MAKE_BID";
export const TERMS_OFFERED = "TERMS_OFFERED";
export const APPROVED = "APPROVED";
export const DECLINE = "DECLINE";

export const gaId = "G-H9DJV24PPP";

export enum ENV {
  DEVELOPMENT = "development",
  STAGING = "staging",
  PRODUCTION = "production",
}

export enum Roles {
  ADMIN = "ADMIN",
  INVESTOR_MEMBER = "INVESTOR_MEMBER",
  ASSOCIATE_MEMBER = "ASSOCIATE_MEMBER",
  NETWORK_MEMBER = "NETWORK_MEMBER",
  NETWORK_INTRODUCER = "NETWORK_INTRODUCER",
}

export enum ActivityAction {
  CASE_CREATION = "CASE_CREATION",
  MAKE_BID = "MAKE_BID",
  OFFERED_TERMS = "OFFERED_TERMS",
}

export enum NavTypes {
  LEXHUB = "LEXHUB",
  LEXINVEST = "LEXINVEST",
  LEXTRADE = "LEXTRADE",
  LEXWELLNES = "LEXWELLNES",
  LEXLEARN = "LEXLEARN",
  LEXRECRUIT = "LEXRECRUIT",
  LEXCONNECT = "LEXCONNECT",
}

export enum DeclineCase {
  WEAK_LEGAL = "Weak Legal Merit: Insufficient evidence or strong legal arguments.",
  HIGH_COSTS = "High Costs: Projected expenses outweigh potential returns.",
  LOW_POTENTIAL_PAYOUT = "Low Potential Payout: Uncertain or minimal financial outcome.",
  TIME_CONSTRAINTS = "Time Constraints: Case duration doesn't align with investment goals.",
  RIST_OF_REPUTATION = "Risk of Reputation: Concerns about negative publicity or image.",
  UNFORESEEN_COMPLEXITY = "Unforeseen Complexity: Case intricacies raise concerns.",
  COUNTER_CLAIMS_OR_RISKS = "Counterclaims or Risks: Strong opposing arguments pose high risks.",
  DOUBTFUL_PRECEDENT = "Doubtful Precedent: Lack of legal precedent introduces uncertainty.",
  PRIVATE_SETTLEMENT = "Private Settlement: Parties likely to resolve the matter privately.",
  CHANGE_IN_CIRCUMSTANCES = "Change in Circumstances: Shift in circumstances affecting viability.",
  OTHER_SPECIFY = "Other: Specify any other reason for opting out.",
}
export enum DeclineCaseStageFour {
  PROGRESS_TO_NEXT_STAGE = "PROGRESS_TO_NEXT_STAGE",
  ON_HOLD = "ON_HOLD",
  DECLINE_CASE = "DECLINE_CASE",
}

export enum AdminCaseActions {
  FAILED_TRIAGE = "FAILED_TRIAGE",
  SEND_TO_MARKETPLACE = "SEND_TO_MARKETPLACE",
  LEXOLENT_REVIEW = "LEXOLENT_REVIEW",
}

export interface Register {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  companyPosition: string;
  companyWebsite: string;
  companyLogo: any;
  role: string;
  introducedBy: string;
  joiningGoal: string;
  informedFrom: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  registrationStatus?: string;
  isFeaturedUser?: boolean;
  phone: string;
  role: string;
  company: string;
  companyPosition: string;
  companyWebsite: string;
  companyLogo: string | null;
  photo: string | null;
  joiningGoal?: string;
  agreementSigned?: boolean;
  membershipAgreementSigned?: boolean;
  ndaSigned?: boolean;
  agreementUrl?: string | null;
  subscribedToNewsletter: boolean;
  isActive?: boolean;
  introducedBy?: string | null;
  rejectingReason?: string | null;
  deactivatingReason?: string | null;
  informedFrom?: string;
  referalCode?: string;
  featuredUser?: {
    bio: string;
    youtubeUrl: string;
    podcastUrl: string;
    linkedinUrl: string;
  };
  createdAt?: Date;
  claimType?: string[] | null;
  investmentAmountLowerBound?: number | null;
  investmentAmountUpperBound?: number | null;
  usefulInformation?: string | null;
  userNote?: string | null;
  country?: string[] | null;
  region?: string[] | null;
  // tag?: string[] | null;
  specialization?: string[] | null;
  membershipAgreementType?: string;
  minimumClaimValue?: number;
  isHouseFundMember?: boolean;
  isAffiliatedChambers?: boolean;
  affiliatedChambersText?: string;
  assignedNetworkMemberId?: string;
  assignedNetworkMember?: {
    id: string;
    firstName: string;
    lastName: string;
    photo: string;
    isFeaturedUser: true;
  };
}

export interface FeaturedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  photo: string | null;
  isFeaturedUser: boolean;
  role: string;
  _count: { cases: number };
  featuredUser?: {
    bio: string;
    youtubeUrl: string;
    podcastUrl: string;
    linkedinUrl: string;
  };
}

export interface CaseStepProps {
  caseId?: string;
  formikRef?: any;
  role?: string;
  userId?: string;
  baseStage?: boolean;
  handleStep?: (step: number) => void;
  setCaseId?: (id: string) => void;
  customHandleStep?: () => void;
}

export interface ConflictCheck {
  proposedFinanceCounterParty: string;
  claimantPlaintiffs: string;
  claimantsPlaintiffsLawyers: string;
  defendantsRespondants: string;
  defendantsRespondantsLawyers: string;
  otherParty: string;
  requestFurtherInformationAt: string;
  confirmConflictFreeAt: string;
  memberNDAconfirmedAt: string;
  conflictCheck?: boolean;
  commentForConfirmConflictFree?: string;
  commentForRequestFurtherInformation?: string;
}

export interface InvestmentSubmission {
  applicableLaw?: string;
  hasAdverseCostExposure?: boolean;
  hasMeritsOpinion?: boolean;
  prospectOfSuccess?: number;
  hasQuantumAnalysis?: boolean;
  estimatedDamages?: number;
  estimatedRecovery?: number;
  hasEnforcementAnalysis?: boolean;
  hasPreActionCorrespondance?: boolean;
  forum?: string[];
  claimType?: string[];
  region?: string[];
  country?: string[];
  budgetEstimate?: number;
  hasFactualBackground?: boolean;
  hasLegalBasis?: boolean;
  timelineToResolutin?: string;
  hasSupportingDocuments?: boolean;
  hasContemporaneousWrittenEvidence?: boolean;
  hasDisputeForumFilingsToDate?: boolean;
  requestFurtherInformationAt?: string;
  confirmConflictFreeAt?: string;
  budgetCurrencyId?: string;
  damagesCurrency?: string;
  damagesCurrencyId?: string;
  hasConfirmProgressionToTriage?: boolean;
  investmentSubmission?: boolean;
  commentForConfirmConflictFree?: string;
  commentForRequestFurtherInformation?: string;
  passNext?: boolean;
}

export interface Triage {
  hasForum?: boolean;
  hasJurisdiction?: boolean;
  hasFactualMemo?: boolean;
  hasLegalAnalysis?: boolean;
  hasQuantumAnalysis?: boolean;
  hasBudget?: boolean;
  hasAdverseCosts?: boolean;
  hasDocuments?: boolean;
  ratioProportionality?: number;
  hasLegalTeam?: boolean;
  hasRequestCallMeeting?: boolean;
  hasRequestFurtherInformation?: boolean;
  hasConfirmTriageInvestmentDecision?: boolean;
  approvedAt?: string;
  triageInvestmentAssesment?: boolean;
  commentForRequestFurtherInformation?: string;
  commentForRequestClientLegalMeeting?: string;
  status?: string;
  isArchived?: boolean;
  statusStage?: "PROGRESS_TO_NEXT_STAGE" | "ON_HOLD" | "DECLINE_CASE";
  hasValidateStep?: boolean;
  passNext?: boolean;
}

export interface InvestmentList {
  caseType: string[];
  minimumClaimValue: number;
  region: string[];
  country: string[];
  investmentAmountMin?: number;
  investmentAmountMax?: number;
  // fiveDaysFileUrl?: string;
  // twentyDaysFileUrl?: string;
  showCaseToLexolent?: boolean;
  showCaseToHouseFund?: boolean;
  showCaseToMarketplace?: boolean;
  approvedAt?: string;
  investmentList: boolean;
  status?: string;
  isArchived?: boolean;
  statusStage?: "PROGRESS_TO_NEXT_STAGE" | "ON_HOLD" | "DECLINE_CASE";
  hasValidateStep?: boolean;
  passNext?: boolean;
}

export interface UnderwritingReview {
  hasMeritsOpinion?: boolean;
  hasBudgetAdverseCosts?: boolean;
  hasQuantumAnalysis?: boolean;
  hasSupportingDocuments?: boolean;
  prospectsOfSuccessLiability?: number;
  counterPartyRisk?: string;
  prospectsOfSuccessQuantum?: number;
  legalTeamRisk?: string;
  ratioProportionality?: number;
  enforcementRisk?: string;
  juridisctionForumRisk?: string;
  collectionRisk?: string;
  evidenceRisk?: string;
  durationRisk?: string;
  requestClientLegalMeetingAt?: string;
  requestFurtherInformationAt?: string;
  confirmUnderWritingDecisionAt?: string;
  approvedAt?: string;
  underwritingReview: boolean;
  commentForRequestFurtherInformation?: string;
  commentForRequestClientLegalMeeting?: string;
  commentForConfirmUnderWritingDecision?: string;
  status?: string;
  isArchived?: boolean;
  statusStage?: "PROGRESS_TO_NEXT_STAGE" | "ON_HOLD" | "DECLINE_CASE";
  hasValidateStep?: boolean;
  passNext?: boolean;
}

export interface NonBindingOffer {
  hasPropseFinancialTerms?: boolean;
  hasAgreeFinancialTerms?: boolean;
  hasAMLcompliance?: boolean;
  AMLcomplianceAt?: string;
  hasProposedTermSheet?: boolean;
  hasAgreeTermSheet?: boolean;
  hasExecuteTermSheet?: boolean;
  confirmCommencementOfExclusivityAt?: string;
  approvedAt?: string;
  nonBindingOffer: boolean;
  commentForConfirmCommencementOfExclusivity?: string;
  status?: string;
  isArchived?: boolean;
  statusStage?: "PROGRESS_TO_NEXT_STAGE" | "ON_HOLD" | "DECLINE_CASE";
  hasValidateStep?: boolean;
  passNext?: boolean;
}

export interface ExlusivityPeriod {
  expiryDate?: string;
  dueDiligenceAt?: string;
  followUpDueDiligenceAt?: string;
  hasProposeSuiteOfLFAdocuments?: boolean;
  hasNegotiateLFAdocuments?: boolean;
  hasObtainAdverseCostsProtectionOffer?: boolean;
  confirmDDApprovalAt?: string;
  confirmLfaAgreedAt?: string;
  confirmAdverseCostProtectionAt?: string;
  approvedAt?: string;
  exclusivityPeriod: boolean;
  commentForConfirmDDApproval?: string;
  commentForConfirmLfaAgreed?: string;
  commentForConfirmAdverseCostProtection?: string;
  status?: string;
  isArchived?: boolean;
  statusStage?: "PROGRESS_TO_NEXT_STAGE" | "ON_HOLD" | "DECLINE_CASE";
  hasValidateStep?: boolean;
  passNext?: boolean;
}

export interface LfaExecution {
  executeLfaAt?: string;
  executeLfaAssociatedDocumentsAt?: string;
  executePrioritiesDeedAt?: string;
  executionOfLawyerRetainerCfaDbaAt?: string;
  confirmLfaExecutionAndCommencementDateAt?: string;
  confirmCaseMonitoringRequirementsAt?: string;
  confirmAdverseCostProtectionInPlaceAt?: string;
  approvedAt?: string;
  lfaExecution: boolean;
  commentForConfirmLfaExecutionAndCommencementDate?: string;
  commentForConfirmAdverseCostProtectionInPlace?: string;
  commentForConfirmCaseMonitoringRequirements?: string;
  passNext?: boolean;
}

export interface Monitoring {
  hasRegularReportingCompliance: boolean;
  regularReportingComplianceAt: string;
  hasBudgetCompliance: boolean;
  budgetComplianceAt: string;
  hasCaseProgressionCompliance: boolean;
  hasAdverseCostsProtectionCompliance: boolean;
  hasReviewDrawdownRequest: boolean;
  reviewDrawdownRequestAt: string;
  hasPayDrawdownRequest: boolean;
  payDrawdownRequestAt: string;
  hasApproveDrawdownRequest: boolean;
  approveDrawdownRequestAt: string;
  confirmDrawdownRequestInPlaceAt: string;
  approvedAt: string;
  monitoring: boolean;
  commentForConfirmDrawdownRequestInPlace?: string;
  passNext?: boolean;
}

export interface Resolution {
  settlementAmount?: number;
  settlementAt?: string;
  judgementOrAwardAmount?: number;
  judgementOrAwardAt?: string;
  hasCompliance?: boolean;
  compliance?: number;
  hasEnforcement?: boolean;
  enforcment?: number;
  hasDistribution?: boolean;
  distrubtion?: number;
  distrubtionAt?: string;
  approvedAt?: string;
  resolution: boolean;
}

export interface Case {
  id: string;
  caseName: string;
  caseIndex: string;
  createdAt: Date;
  isArchived?: boolean;
  user: { firstName: string; lastName: string; role: string; photo: string };
  claimType: { id: string; name: string; translations: { ar: string } }[];
  tag: { id: string; name: string; translations: { ar: string } }[];
  investmentSubmission: {
    budgetEstimate: number;
  };
  investmentList: {
    country: { id: string; name: string; translations: { ar: string } }[];
    region: { id: string; name: string; translations: { ar: string } }[];
    showCaseToLexolent: boolean;
    showCaseToHouseFund: boolean;
    showCaseToMarketplace: boolean;
  };
  savedCases?: {
    id: string;
  }[];
  bids?: {
    status: string;
    currentStatus: string;
    user: { id: string; investorIDNumber: string };
  }[];
}

export interface Bids {
  id: string;
  user: {
    investorIDNumber: string;
  };
  case: Case;
  currentStatus: string;
  status: string;
  expiredIn: Date;
  createdAt: Date;
  dataRoomLinkAtPassedConflictPermission: boolean;
  dataRoomLinkAtMakeBidPermission: boolean;
  isExpired: boolean;
}
