import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import jwtDecode from "jwt-decode";
import { Register } from "../../types";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";
import Swal from "sweetalert2";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const authSclice = createSlice({
  name: "auth",
  initialState: {
    loginState: { ...initialState },
    registerState: { ...initialState },
    forgotState: { ...initialState },
    resetState: { ...initialState },
  },
  reducers: {
    loginStart(state) {
      state.loginState.data = null;
      state.loginState.error = false;
      state.loginState.errorMessage = null;
      state.loginState.loading = true;
      state.loginState.success = false;
    },
    loginSuccess(state, action) {
      state.loginState.data = action.payload;
      state.loginState.error = false;
      state.loginState.errorMessage = null;
      state.loginState.loading = false;
      state.loginState.success = true;
    },
    loginFail(state, action) {
      state.loginState.data = null;
      state.loginState.errorMessage = action.payload;
      state.loginState.error = true;
      state.loginState.loading = false;
      state.loginState.success = false;
    },
    loginReset(state) {
      state.loginState.data = null;
      state.loginState.error = false;
      state.loginState.errorMessage = null;
      state.loginState.loading = false;
      state.loginState.success = false;
    },
    registerStart(state) {
      state.registerState.data = null;
      state.registerState.error = false;
      state.registerState.errorMessage = null;
      state.registerState.loading = true;
      state.registerState.success = false;
    },
    registerSuccess(state, action) {
      state.registerState.data = action.payload;
      state.registerState.error = false;
      state.registerState.errorMessage = null;
      state.registerState.loading = false;
      state.registerState.success = true;
    },
    registerFail(state, action) {
      state.registerState.data = null;
      state.registerState.errorMessage = action.payload;
      state.registerState.error = true;
      state.registerState.loading = false;
      state.registerState.success = false;
    },
    registerReset(state) {
      state.registerState.data = null;
      state.registerState.error = false;
      state.registerState.errorMessage = null;
      state.registerState.loading = false;
      state.registerState.success = false;
    },
    forgotStart(state) {
      state.forgotState.data = null;
      state.forgotState.error = false;
      state.forgotState.errorMessage = null;
      state.forgotState.loading = true;
      state.forgotState.success = false;
    },
    forgotSuccess(state, action) {
      state.forgotState.data = action.payload;
      state.forgotState.error = false;
      state.forgotState.errorMessage = null;
      state.forgotState.loading = false;
      state.forgotState.success = true;
    },
    forgotFail(state, action) {
      state.forgotState.data = null;
      state.forgotState.errorMessage = action.payload;
      state.forgotState.error = true;
      state.forgotState.loading = false;
      state.forgotState.success = false;
    },
    forgotReset(state) {
      state.forgotState.data = null;
      state.forgotState.error = false;
      state.forgotState.errorMessage = null;
      state.forgotState.loading = false;
      state.forgotState.success = false;
    },
    resetStart(state) {
      state.resetState.data = null;
      state.resetState.error = false;
      state.resetState.errorMessage = null;
      state.resetState.loading = true;
      state.resetState.success = false;
    },
    resetSuccess(state, action) {
      state.resetState.data = action.payload;
      state.resetState.error = false;
      state.resetState.errorMessage = null;
      state.resetState.loading = false;
      state.resetState.success = true;
    },
    resetFail(state, action) {
      state.resetState.data = null;
      state.resetState.errorMessage = action.payload;
      state.resetState.error = true;
      state.resetState.loading = false;
      state.resetState.success = false;
    },
    resetPasswordReset(state) {
      state.resetState.data = null;
      state.resetState.error = false;
      state.resetState.errorMessage = null;
      state.resetState.loading = false;
      state.resetState.success = false;
    },
  },
});
export const login =
  (
    payload: { email: string; password: string },
    options: {
      language: string;
      navigate: (path: string) => void;
      pathname: string;
      onSuccessMessage: string;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, navigate, pathname, onSuccessMessage } = options;
    try {
      dispatch(loginStart());
      const { email, password } = payload;
      const response = await APIClient.post("/auth/login", {
        email: email.toLowerCase(),
        password,
      });
      if (response.data && response.data.data) {
        const { accessToken } = response.data.data;
        const decoded: { exp: any; role: string } = jwtDecode(accessToken);
        const expiresIn = new Date(decoded.exp * 1000).toISOString();
        dispatch(loginSuccess(accessToken));
        localStorage.setItem("lexolent-token", accessToken);
        localStorage.setItem("expiresIn", expiresIn);
        Notification("success", onSuccessMessage);
        navigate(pathname);
      } else if (response && response.data && response.data.errors) {
        dispatch(loginFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, loginFail, language);
    }
  };
export const resetLogin = () => async (dispatch: AppDispatch) => {
  dispatch(loginReset());
};
export const register =
  (
    payload: Register,
    options: { language: string; handleSuccess: () => void }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, handleSuccess } = options;
    try {
      dispatch(registerStart());

      const response = await APIClient.post("/auth/register", payload);
      if (response.data && response.data.success) {
        dispatch(registerSuccess(response.data.success));
        handleSuccess();
      } else if (response && response.data && response.data.errors) {
        dispatch(registerFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, registerFail, language);
    }
  };
export const resetRegister = () => async (dispatch: AppDispatch) => {
  dispatch(registerReset());
};
export const forgot =
  (
    payload: { email: string },
    options: { language: string; onSuccessMessage: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, onSuccessMessage } = options;
    try {
      dispatch(forgotStart());

      const response = await APIClient.patch("/auth/reset-password", payload);
      if (response.data && response.data.success) {
        dispatch(forgotSuccess(response.data.success));
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(forgotFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, forgotFail, language);
    }
  };
export const resetForgot = () => async (dispatch: AppDispatch) => {
  dispatch(forgotReset());
};
export const reset =
  (
    payload: { token: string; password: string; confirmPassword: string },
    options: {
      language: string;
      navigate: (pathname: string, options: { replace: boolean }) => void;
      pathname: string;
      onSuccessMessage: string;
      showSwal?: boolean;
      swalTitle: string;
      swalText: string;
      proceedText: string;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      navigate,
      pathname,
      onSuccessMessage,
      showSwal,
      swalTitle,
      swalText,
      proceedText,
    } = options;
    try {
      dispatch(resetStart());

      const { token, password, confirmPassword } = payload;
      const response = await APIClient.patch(
        `/auth/update-password?token=${token}`,
        {
          password,
          confirmPassword,
        }
      );
      if (response.data && response.data.success) {
        dispatch(resetSuccess(response.data.success));
        if (showSwal) {
          Swal.fire({
            title: `${swalTitle}`,
            text: `${swalText}`,
            icon: "success",
            iconColor: "#21ac79",
            showCancelButton: false,
            confirmButtonColor: "#b89e4f",
            cancelButtonColor: "#ff4d4f",
            confirmButtonText: `${proceedText}`,
          }).then(
            (result) =>
              result.isConfirmed && navigate(pathname, { replace: true })
          );
        } else {
          Notification("success", onSuccessMessage);
          navigate(pathname, { replace: true });
        }
      } else if (response && response.data && response.data.errors) {
        dispatch(resetFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, resetFail, language);
    }
  };
export const resetResetPassword = () => async (dispatch: AppDispatch) => {
  dispatch(resetPasswordReset());
};
const {
  loginFail,
  loginStart,
  loginSuccess,
  loginReset,
  registerFail,
  registerStart,
  registerSuccess,
  registerReset,
  forgotFail,
  forgotStart,
  forgotSuccess,
  forgotReset,
  resetFail,
  resetStart,
  resetSuccess,
  resetPasswordReset,
} = authSclice.actions;
export default authSclice.reducer;
