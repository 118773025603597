import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth";
import users from "./users";
import me from "./me";
import tags from "./tags";
import caseTypes from "./caseTypes";
import language from "./language";
import countries from "./countries";
import currency from "./currency";
import cases from "./cases";
import forums from "./forums";
import claimTypes from "./claimtype";
import jurisdictionRegion from "./jurisdictionRegion";
import dashboard from "./dashboard";
import bids from "./bids";
import specializations from "./specializations";

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  whitelist: [
    "auth, users, me, language, tags, caseTypes, countries, currency, cases, jurisdictionRegion, claimTypes, forums, dashboard, bids, specializations, version",
  ],
};

const reducers = combineReducers({
  auth,
  users,
  me,
  language,
  tags,
  caseTypes,
  countries,
  currency,
  cases,
  forums,
  claimTypes,
  jurisdictionRegion,
  dashboard,
  bids,
  specializations,
  version: (state = 0) => state,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store, null, () => {
  const state: any = persistor.getState();
  if (state && state.version !== persistConfig.version) {
    persistor.purge(); // Clear persisted state if version mismatches
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
