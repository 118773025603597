import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const specializationsSlice = createSlice({
  name: "specializations",
  initialState: {
    getAll: { ...initialState },
    getOne: { ...initialState },
    create: { ...initialState },
    update: { ...initialState },
    delete: { ...initialState },
  },
  reducers: {
    getAllSpecializationsStart(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = true;
      state.getAll.success = false;
    },
    getAllSpecializationsSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = true;
    },
    getAllSpecializationsFail(state, action) {
      state.getAll.data = null;
      state.getAll.errorMessage = action.payload;
      state.getAll.error = true;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllSpecializationsReset(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneSpecializationStart(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = true;
      state.getOne.success = false;
    },
    getOneSpecializationSuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = true;
    },
    getOneSpecializationFail(state, action) {
      state.getOne.data = null;
      state.getOne.errorMessage = action.payload;
      state.getOne.error = true;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneSpecializationReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    createSpecializationStart(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = true;
      state.create.success = false;
    },
    createSpecializationSuccess(state, action) {
      state.create.data = action.payload;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = true;
    },
    createSpecializationFail(state, action) {
      state.create.data = null;
      state.create.errorMessage = action.payload;
      state.create.error = true;
      state.create.loading = false;
      state.create.success = false;
    },
    createSpecializationReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateSpecializationStart(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = true;
      state.update.success = false;
    },
    updateSpecializationSuccess(state, action) {
      state.update.data = action.payload;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = true;
    },
    updateSpecializationFail(state, action) {
      state.update.data = null;
      state.update.errorMessage = action.payload;
      state.update.error = true;
      state.update.loading = false;
      state.update.success = false;
    },
    updateSpecializationReset(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = false;
    },
    deleteSpecializationStart(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = true;
      state.delete.success = false;
    },
    deleteSpecializationSuccess(state, action) {
      state.delete.data = action.payload;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = true;
    },
    deleteSpecializationFail(state, action) {
      state.delete.data = null;
      state.delete.errorMessage = action.payload;
      state.delete.error = true;
      state.delete.loading = false;
      state.delete.success = false;
    },
    deleteSpecializationReset(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = false;
    },
  },
});
export const getSpecializations =
  (
    payload: {
      page?: number;
      pageSize?: number;
      search?: string;
      sortOrder?: string;
      select?: string;
      pagination?: boolean;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllSpecializationsStart());
      const response = await APIClient.get("/specializations", {
        params: payload,
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllSpecializationsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllSpecializationsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllSpecializationsFail, language);
    }
  };
export const clearSpecializations = () => async (dispatch: AppDispatch) => {
  dispatch(getAllSpecializationsReset());
};
export const getOneSpecialization =
  (payload: { specializationId: string }, options: { language: string }) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getOneSpecializationStart());
      const { specializationId } = payload;
      const response = await APIClient.get(
        `/specializations/${specializationId}`
      );
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getOneSpecializationSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneSpecializationFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneSpecializationFail, language);
    }
  };
export const clearSpecialization = () => async (dispatch: AppDispatch) => {
  dispatch(getOneSpecializationReset());
};
export const createSpecialization =
  (
    payload: { name: string; translations?: { ar: string } },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(createSpecializationStart());

      const { name, translations } = payload;
      const response = await APIClient.post("/specializations", {
        name,
        translations,
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(createSpecializationSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(createSpecializationFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createSpecializationFail, language);
    }
  };
export const clearCreateSpecialization =
  () => async (dispatch: AppDispatch) => {
    dispatch(createSpecializationReset());
  };
export const updateSpecialization =
  (
    payload: {
      specializationId: string;
      name?: string;
      isActive?: boolean;
      translations?: { ar: string };
    },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(updateSpecializationStart());

      const { specializationId } = payload;
      const body: any = payload;
      delete body["specializationId"];
      const response = await APIClient.patch(
        `/specializations/${specializationId}`,
        body
      );
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(updateSpecializationSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(updateSpecializationFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateSpecializationFail, language);
    }
  };
export const clearUpdateSpecialization =
  () => async (dispatch: AppDispatch) => {
    dispatch(updateSpecializationReset());
  };
export const deleteSpecialization =
  (
    payload: { specializationId: string },
    options: {
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(deleteSpecializationStart());
      const { specializationId } = payload;
      const { recall, onClose, onSuccessMessage } = options;
      const response = await APIClient.delete(
        `/specializations/${specializationId}`
      );
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(deleteSpecializationSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(deleteSpecializationFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      if (
        error &&
        error.response?.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "string"
      ) {
        dispatch(deleteSpecializationFail(error.response.data.errors));
        Notification("error", error.response.data.errors);
      } else if (
        Object.prototype.toString.call(error.response.data.errors) ===
        "[object Object]"
      ) {
        const message = Object.keys(error.response.data.errors)
          .map((key) => error.response.data.errors[key])
          .join(".\n");

        dispatch(deleteSpecializationFail(message));
        Notification("error", message);
      } else {
        dispatch(deleteSpecializationFail(error.message));
        Notification("error", error.message);
      }
    }
  };
export const clearDeleteSpecialization =
  () => async (dispatch: AppDispatch) => {
    dispatch(deleteSpecializationReset());
  };
const {
  getAllSpecializationsFail,
  getAllSpecializationsStart,
  getAllSpecializationsSuccess,
  getAllSpecializationsReset,
  getOneSpecializationFail,
  getOneSpecializationStart,
  getOneSpecializationSuccess,
  getOneSpecializationReset,
  createSpecializationFail,
  createSpecializationStart,
  createSpecializationSuccess,
  createSpecializationReset,
  updateSpecializationFail,
  updateSpecializationStart,
  updateSpecializationSuccess,
  updateSpecializationReset,
  deleteSpecializationFail,
  deleteSpecializationStart,
  deleteSpecializationSuccess,
  deleteSpecializationReset,
} = specializationsSlice.actions;
export default specializationsSlice.reducer;
