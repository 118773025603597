import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translationEN.json";
import translationAR from "./locales/ar/translationAR.json";

const defaultLanguage = "en";

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: !defaultLanguage ? "en" : defaultLanguage,
  // keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
