import { useTranslation } from "react-i18next";
import lexolentLong from "../../../../assets/images/lexolent-long.svg";
import { Notification } from "../../../../utils/Notification";
import { useNavigate } from "react-router-dom";

export const LogoSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    try {
      navigate("/dashboard");
    } catch (e) {
      return Notification("error", t("errorNavigating"));
    }
  };
  return (
    <div style={{ flexGrow: 1 }}>
      <img
        src={lexolentLong}
        onClick={handleNavigate}
        alt="Lexolent"
        className="logo"
      />
    </div>
  );
};
