import { Button, Col, Row, Space } from "antd";
import { NavTypes } from "../../../types";
interface Nav {
  key: string;
  title: string;
  path: string;
  comingSoon: boolean;
}
interface Props {
  isMenuOpen: boolean;
  nav: Nav[];
  handleClick: (nav: Nav) => void;
}
export const MobileMenu = ({ isMenuOpen, nav, handleClick }: Props) => {
  return (
    <Space.Compact
      block
      direction="horizontal"
      className={`sm-menu ${isMenuOpen ? "menu-show" : "menu-hide"}`}
    >
      <Row justify="center" style={{ height: "50vh", width: "100%" }}>
        {nav.map((e) =>
          e.key === NavTypes.LEXHUB ? (
            <Col xs={24} key={e.key}>
              <Row justify="center">
                <Button
                  onClick={() => handleClick(e)}
                  className="f-20 l-24 nav-main-item"
                  type="link"
                >
                  {e.title}
                </Button>
              </Row>
            </Col>
          ) : (
            <Col xs={24} key={e.key}>
              <Row justify="center">
                <Button
                  className="nav-item f-20 f-400 l-24"
                  onClick={() => handleClick(e)}
                  type="link"
                >
                  {e.title}
                </Button>
              </Row>
            </Col>
          )
        )}
      </Row>
    </Space.Compact>
  );
};
