import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import APIClient from "../../services/ApiClient";
import { Notification } from "../../utils/Notification";
import { AppDispatch } from "..";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const currencySlice = createSlice({
  name: "currency",
  initialState: {
    getAll: { ...initialState },
    getOne: { ...initialState },
    create: { ...initialState },
    update: { ...initialState },
    delete: { ...initialState },
  },
  reducers: {
    getAllCurrencyStart(state) {
      state.getAll.data = null;
      state.getAll.loading = true;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.success = false;
    },
    getAllCurrencySuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.loading = false;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.success = true;
    },
    getAllCurrencyFail(state, action: PayloadAction<string>) {
      state.getAll.data = null;
      state.getAll.error = true;
      state.getAll.errorMessage = action.payload;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllCurrencyReset(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneCurrencyStart(state) {
      state.getOne.data = null;
      state.getOne.loading = true;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.success = false;
    },
    getOneCurrencySuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.loading = false;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.success = true;
    },
    getOneCurrencyFail(state, action: PayloadAction<string>) {
      state.getOne.data = null;
      state.getOne.error = true;
      state.getOne.errorMessage = action.payload;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneCurrencyReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    createCurrencyStart(state) {
      state.create.data = null;
      state.create.loading = true;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.success = false;
    },
    createCurrencySuccess(state, action) {
      state.create.data = action.payload;
      state.create.loading = false;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.success = true;
    },
    createCurrencyFail(state, action: PayloadAction<string>) {
      state.create.data = null;
      state.create.error = true;
      state.create.errorMessage = action.payload;
      state.create.loading = false;
      state.create.success = false;
    },
    createCurrencyReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateCurrencyStart(state) {
      state.update.loading = true;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.success = false;
    },
    updateCurrencySuccess(state, action) {
      state.update.data = { docs: [action.payload], totalDocs: 1 };
      state.update.loading = false;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.success = true;
    },
    updateCurrencyFail(state, action: PayloadAction<string | undefined>) {
      state.update.error = true;
      state.update.errorMessage = action.payload || null;
      state.update.loading = false;
      state.update.success = false;
    },
    updateCurrencyReset(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = false;
    },
    deleteCurrencyStart(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = true;
      state.delete.success = false;
    },
    deleteCurrencySuccess(state, action) {
      state.delete.data = action.payload;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = true;
    },
    deleteCurrencyFail(state, action) {
      state.delete.data = null;
      state.delete.errorMessage = action.payload;
      state.delete.error = true;
      state.delete.loading = false;
      state.delete.success = false;
    },
    deleteCurrencyReset(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = false;
    },
  },
});
export const getAllCurrencies =
  (
    payload: { page?: number; pageSize?: number; pagination?: boolean },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllCurrencyStart());
      const response = await APIClient.get("/currencies", { params: payload });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllCurrencySuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllCurrencyFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllCurrencyFail, language);
    }
  };
export const clearCurrency = () => async (dispatch: AppDispatch) => {
  dispatch(getAllCurrencyReset());
};
export const getOneCurrency =
  (payload: { currenciesId: string }, options: { language: string }) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getOneCurrencyStart());
      const { currenciesId } = payload;
      const response = await APIClient.get(`/currencies/${currenciesId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getOneCurrencySuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneCurrencyFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneCurrencyFail, language);
    }
  };
export const clearOneCurrency = () => async (dispatch: AppDispatch) => {
  dispatch(getOneCurrencyReset());
};
export const createCurrency =
  (
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(createCurrencyStart());
      const response = await APIClient.post("/currencies", payload);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(createCurrencySuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(createCurrencyFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createCurrencyFail, language);
    }
  };
export const clearCreateCurrency = () => async (dispatch: AppDispatch) => {
  dispatch(createCurrencyReset());
};
export const updateCurrency =
  (
    currenciesId: string,
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(updateCurrencyStart());
      const response = await APIClient.patch(
        `/currencies/${currenciesId}`,
        payload
      );
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(updateCurrencySuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(updateCurrencyFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateCurrencyFail, language);
    }
  };
export const clearUpdateCurrency = () => async (dispatch: AppDispatch) => {
  dispatch(updateCurrencyReset());
};
export const deleteCurrency =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(deleteCurrencyStart());
      const { currenciesId } = payload;
      const response = await APIClient.patch(`/currencies/${currenciesId}`, {
        isActive: false,
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(deleteCurrencySuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(deleteCurrencyFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      if (
        error &&
        error.response?.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "string"
      ) {
        dispatch(deleteCurrencyFail(error.response.data.errors));
        Notification("error", error.response.data.errors);
      } else if (
        Object.prototype.toString.call(error.response.data.errors) ===
        "[object Object]"
      ) {
        const message = Object.keys(error.response.data.errors)
          .map((key) => error.response.data.errors[key])
          .join(".\n");

        dispatch(deleteCurrencyFail(message));
        Notification("error", message);
      } else {
        dispatch(deleteCurrencyFail(error.message));
        Notification("error", error.message);
      }
    }
  };
export const clearDeleteCurrency = () => async (dispatch: AppDispatch) => {
  dispatch(deleteCurrencyReset());
};

export const {
  getAllCurrencyStart,
  getAllCurrencySuccess,
  getAllCurrencyFail,
  getAllCurrencyReset,
  getOneCurrencyStart,
  getOneCurrencySuccess,
  getOneCurrencyFail,
  getOneCurrencyReset,
  createCurrencyStart,
  createCurrencySuccess,
  createCurrencyFail,
  createCurrencyReset,
  updateCurrencyStart,
  updateCurrencySuccess,
  updateCurrencyFail,
  updateCurrencyReset,
  deleteCurrencyStart,
  deleteCurrencySuccess,
  deleteCurrencyFail,
  deleteCurrencyReset,
} = currencySlice.actions;

export default currencySlice.reducer;
