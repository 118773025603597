import { SettingOutlined } from "@ant-design/icons";
import { Button, FloatButton, Space, Tooltip, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useTranslation } from "react-i18next";
import { changeDirection, changeLanguage } from "../../../store/language";
import { AR, EN, ltr, rtl } from "../../../types";

export const CustomizationButton = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();

  const { language } = useSelector((state: RootState) => state.language);

  const changeLang = (language: string) => {
    if ([EN, AR].includes(language)) {
      dispatch(changeLanguage(language));
      if (language === EN) dispatch(changeDirection(ltr));
      else if (language === AR) dispatch(changeDirection(rtl));
      i18n.changeLanguage(language);
    }
  };

  return (
    <FloatButton.Group
      className="custom-float-button"
      trigger="hover"
      icon={<SettingOutlined />}
    >
      <Tooltip
        placement="left"
        color="#fff"
        title={
          <Space.Compact className="settings-section">
            <Button
              type="link"
              className={`settings-button half-width f-12 ${
                language === EN ? "active" : undefined
              }`}
              onClick={() => changeLang(EN)}
            >
              EN
            </Button>
            <Button
              type="link"
              className={`settings-button half-width f-12 ${
                language === AR ? "active" : undefined
              }`}
              onClick={() => changeLang(AR)}
            >
              AR
            </Button>
          </Space.Compact>
        }
      >
        <FloatButton
          icon={
            <Typography.Text className="f-12">
              {language === EN ? "EN" : "AR"}
            </Typography.Text>
          }
        />
      </Tooltip>
    </FloatButton.Group>
  );
};
