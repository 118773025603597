import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const forumsSlice = createSlice({
  name: "forums",
  initialState: {
    getAll: { ...initialState },
    getOne: { ...initialState },
    create: { ...initialState },
    update: { ...initialState },
    delete: { ...initialState },
  },
  reducers: {
    getAllForumsStart(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = true;
      state.getAll.success = false;
    },
    getAllForumsSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = true;
    },
    getAllForumsFail(state, action) {
      state.getAll.data = null;
      state.getAll.errorMessage = action.payload;
      state.getAll.error = true;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllForumsReset(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneForumStart(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = true;
      state.getOne.success = false;
    },
    getOneForumSuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = true;
    },
    getOneForumFail(state, action) {
      state.getOne.data = null;
      state.getOne.errorMessage = action.payload;
      state.getOne.error = true;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneForumReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    createForumStart(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = true;
      state.create.success = false;
    },
    createForumSuccess(state, action) {
      state.create.data = action.payload;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = true;
    },
    createForumFail(state, action) {
      state.create.data = null;
      state.create.errorMessage = action.payload;
      state.create.error = true;
      state.create.loading = false;
      state.create.success = false;
    },
    createForumReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateForumStart(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = true;
      state.update.success = false;
    },
    updateForumSuccess(state, action) {
      state.update.data = action.payload;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = true;
    },
    updateForumFail(state, action) {
      state.update.data = null;
      state.update.errorMessage = action.payload;
      state.update.error = true;
      state.update.loading = false;
      state.update.success = false;
    },
    updateForumReset(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = false;
    },
    deleteForumStart(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = true;
      state.delete.success = false;
    },
    deleteForumSuccess(state, action) {
      state.delete.data = action.payload;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = true;
    },
    deleteForumFail(state, action) {
      state.delete.data = null;
      state.delete.errorMessage = action.payload;
      state.delete.error = true;
      state.delete.loading = false;
      state.delete.success = false;
    },
    deleteForumReset(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = false;
    },
  },
});
export const getForums =
  (
    payload: {
      page?: number;
      pageSize?: number;
      search?: string;
      sortOrder?: string;
      select?: string;
      pagination?: boolean;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllForumsStart());
      const response = await APIClient.get("/forums", { params: payload });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllForumsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllForumsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllForumsFail, language);
    }
  };
export const clearForums = () => async (dispatch: AppDispatch) => {
  dispatch(getAllForumsReset());
};
export const getOneForum =
  (payload: { forumId: string }, options: { language: string }) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getOneForumStart());
      const { forumId } = payload;
      const response = await APIClient.get(`/forums/${forumId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getOneForumSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneForumFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneForumFail, language);
    }
  };
export const clearForum = () => async (dispatch: AppDispatch) => {
  dispatch(getOneForumReset());
};
export const createForum =
  (
    payload: { name: string; translations?: { ar: string } },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(createForumStart());

      const { name, translations } = payload;
      const response = await APIClient.post("/forums", { name, translations });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(createForumSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(createForumFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createForumFail, language);
    }
  };
export const clearCreateForum = () => async (dispatch: AppDispatch) => {
  dispatch(createForumReset());
};
export const updateForum =
  (
    payload: {
      forumId: string;
      name?: string;
      isActive?: boolean;
      translations?: { ar: string };
    },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(updateForumStart());

      const { forumId } = payload;
      const body: any = payload;
      delete body["forumId"];
      const response = await APIClient.patch(`/forums/${forumId}`, body);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(updateForumSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(updateForumFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateForumFail, language);
    }
  };
export const clearUpdateForum = () => async (dispatch: AppDispatch) => {
  dispatch(updateForumReset());
};
export const deleteForum =
  (
    payload: { forumId: string },
    options: {
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(deleteForumStart());
      const { forumId } = payload;
      const { recall, onClose, onSuccessMessage } = options;
      const response = await APIClient.delete(`/forums/${forumId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(deleteForumSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(deleteForumFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      if (
        error &&
        error.response?.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "string"
      ) {
        dispatch(deleteForumFail(error.response.data.errors));
        Notification("error", error.response.data.errors);
      } else if (
        Object.prototype.toString.call(error.response.data.errors) ===
        "[object Object]"
      ) {
        const message = Object.keys(error.response.data.errors)
          .map((key) => error.response.data.errors[key])
          .join(".\n");

        dispatch(deleteForumFail(message));
        Notification("error", message);
      } else {
        dispatch(deleteForumFail(error.message));
        Notification("error", error.message);
      }
    }
  };
export const clearDeleteForum = () => async (dispatch: AppDispatch) => {
  dispatch(deleteForumReset());
};
const {
  getAllForumsFail,
  getAllForumsStart,
  getAllForumsSuccess,
  getAllForumsReset,
  getOneForumFail,
  getOneForumStart,
  getOneForumSuccess,
  getOneForumReset,
  createForumFail,
  createForumStart,
  createForumSuccess,
  createForumReset,
  updateForumFail,
  updateForumStart,
  updateForumSuccess,
  updateForumReset,
  deleteForumFail,
  deleteForumStart,
  deleteForumSuccess,
  deleteForumReset,
} = forumsSlice.actions;
export default forumsSlice.reducer;
