import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const meSlice = createSlice({
  name: "me",
  initialState: {
    getMe: { ...initialState },
    updateMe: { ...initialState },
    updatePassword: { ...initialState },
    uploadPhoto: { ...initialState },
    uploadLogo: { ...initialState },
  },
  reducers: {
    getMeStart(state) {
      state.getMe.data = null;
      state.getMe.error = false;
      state.getMe.errorMessage = null;
      state.getMe.loading = true;
      state.getMe.success = false;
    },
    getMeSuccess(state, action) {
      state.getMe.data = action.payload;
      state.getMe.error = false;
      state.getMe.errorMessage = null;
      state.getMe.loading = false;
      state.getMe.success = true;
    },
    getMeFail(state, action) {
      state.getMe.data = null;
      state.getMe.errorMessage = action.payload;
      state.getMe.error = true;
      state.getMe.loading = false;
      state.getMe.success = false;
    },
    getMeReset(state) {
      state.getMe.data = null;
      state.getMe.error = false;
      state.getMe.errorMessage = null;
      state.getMe.loading = false;
      state.getMe.success = false;
    },
    updateMeStart(state) {
      state.updateMe.data = null;
      state.updateMe.error = false;
      state.updateMe.errorMessage = null;
      state.updateMe.loading = true;
      state.updateMe.success = false;
    },
    updateMeSuccess(state, action) {
      state.updateMe.data = action.payload;
      state.updateMe.error = false;
      state.updateMe.errorMessage = null;
      state.updateMe.loading = false;
      state.updateMe.success = true;
    },
    updateMeFail(state, action) {
      state.updateMe.data = null;
      state.updateMe.errorMessage = action.payload;
      state.updateMe.error = true;
      state.updateMe.loading = false;
      state.updateMe.success = false;
    },
    updateMeReset(state) {
      state.updateMe.data = null;
      state.updateMe.error = false;
      state.updateMe.errorMessage = null;
      state.updateMe.loading = false;
      state.updateMe.success = false;
    },
    updatePasswordStart(state) {
      state.updatePassword.data = null;
      state.updatePassword.error = false;
      state.updatePassword.errorMessage = null;
      state.updatePassword.loading = true;
      state.updatePassword.success = false;
    },
    updatePasswordSuccess(state, action) {
      state.updatePassword.data = action.payload;
      state.updatePassword.error = false;
      state.updatePassword.errorMessage = null;
      state.updatePassword.loading = false;
      state.updatePassword.success = true;
    },
    updatePasswordFail(state, action) {
      state.updatePassword.data = null;
      state.updatePassword.errorMessage = action.payload;
      state.updatePassword.error = true;
      state.updatePassword.loading = false;
      state.updatePassword.success = false;
    },
    updatePasswordReset(state) {
      state.updatePassword.data = null;
      state.updatePassword.error = false;
      state.updatePassword.errorMessage = null;
      state.updatePassword.loading = false;
      state.updatePassword.success = false;
    },
    uploadPhotoStart(state) {
      state.uploadPhoto.data = null;
      state.uploadPhoto.error = false;
      state.uploadPhoto.errorMessage = null;
      state.uploadPhoto.loading = true;
      state.uploadPhoto.success = false;
    },
    uploadPhotoSuccess(state, action) {
      state.uploadPhoto.data = action.payload;
      state.uploadPhoto.error = false;
      state.uploadPhoto.errorMessage = null;
      state.uploadPhoto.loading = false;
      state.uploadPhoto.success = true;
    },
    uploadPhotoFail(state, action) {
      state.uploadPhoto.data = null;
      state.uploadPhoto.errorMessage = action.payload;
      state.uploadPhoto.error = true;
      state.uploadPhoto.loading = false;
      state.uploadPhoto.success = false;
    },
    uploadPhotoReset(state) {
      state.uploadPhoto.data = null;
      state.uploadPhoto.error = false;
      state.uploadPhoto.errorMessage = null;
      state.uploadPhoto.loading = false;
      state.uploadPhoto.success = false;
    },
    uploadLogoStart(state) {
      state.uploadLogo.data = null;
      state.uploadLogo.error = false;
      state.uploadLogo.errorMessage = null;
      state.uploadLogo.loading = true;
      state.uploadLogo.success = false;
    },
    uploadLogoSuccess(state, action) {
      state.uploadLogo.data = action.payload;
      state.uploadLogo.error = false;
      state.uploadLogo.errorMessage = null;
      state.uploadLogo.loading = false;
      state.uploadLogo.success = true;
    },
    uploadLogoFail(state, action) {
      state.uploadLogo.data = null;
      state.uploadLogo.errorMessage = action.payload;
      state.uploadLogo.error = true;
      state.uploadLogo.loading = false;
      state.uploadLogo.success = false;
    },
    uploadLogoReset(state) {
      state.uploadLogo.data = null;
      state.uploadLogo.error = false;
      state.uploadLogo.errorMessage = null;
      state.uploadLogo.loading = false;
      state.uploadLogo.success = false;
    },
  },
});
export const getMe =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getMeStart());
      const response = await APIClient.get("/me");
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getMeSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getMeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getMeFail, language);
    }
  };
export const clearMe = () => async (dispatch: AppDispatch) => {
  dispatch(getMeReset());
};
export const updateMe =
  (
    payload: any,
    options: {
      language: string;
      firstUpdate?: boolean;
      navigate?: (path: string, options: { state: any }) => void;
      pathname?: string;
      recall?: () => void;
      onSuccessMessage: string;
      showToast: boolean;
      closeModal?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      recall,
      navigate,
      pathname,
      firstUpdate,
      closeModal,
      showToast,
    } = options;
    try {
      dispatch(updateMeStart());
      const response = await APIClient.patch(`/me`, payload);
      if (response.data && response.data.success) {
        const { user } = response.data.data;
        dispatch(updateMeSuccess(user));
        if (recall) recall();
        if (showToast) Notification("success", onSuccessMessage);
        if (navigate && firstUpdate && pathname)
          navigate(pathname, { state: { firstUpdate } });
        if (closeModal) closeModal();
      } else if (response && response.data && response.data.errors) {
        dispatch(updateMeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateMeFail, language);
    }
  };
export const clearUpdateMe = () => async (dispatch: AppDispatch) => {
  dispatch(updateMeReset());
};
export const updatePassword =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(updatePasswordStart());

      const response = await APIClient.patch("/me/update-password", {});
      if (response.data && response.data.success) {
        const { user } = response.data.data;
        dispatch(updatePasswordSuccess(user));
      } else if (response && response.data && response.data.errors) {
        dispatch(updatePasswordFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updatePasswordFail, language);
    }
  };
export const clearUpdatePassword = () => async (dispatch: AppDispatch) => {
  dispatch(updatePasswordReset());
};
export const uploadMyPhoto =
  (
    payload: { formData: any },
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      recall?: boolean;
      shouldNavigate?: boolean;
      pathname?: string;
      navigate?: (path: string) => void;
      recallFunction?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      recall,
      shouldNavigate,
      navigate,
      pathname,
      recallFunction,
    } = options;
    try {
      dispatch(uploadPhotoStart());

      const { formData } = payload;
      const response = await APIClient.post(`/me/avatar`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(uploadPhotoSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (recall && recallFunction) recallFunction();
        if (shouldNavigate && navigate && pathname) {
          navigate(pathname);
        }
      } else if (response && response.data && response.data.errors) {
        dispatch(uploadPhotoFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, uploadPhotoFail, language);
    }
  };
export const clearUploadPhoto = () => async (dispatch: AppDispatch) => {
  dispatch(uploadPhotoReset());
};
export const uploadMyCompanyLogo =
  (
    payload: { formData: any },
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      recall?: boolean;
      shouldNavigate?: boolean;
      pathname?: string;
      skipStep?: boolean;
      navigate?: (path: string) => void;
      recallFunction?: (skipStep?: boolean) => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      recall,
      shouldNavigate,
      navigate,
      pathname,
      recallFunction,
      skipStep,
    } = options;
    try {
      dispatch(uploadLogoStart());

      const { formData } = payload;
      const response = await APIClient.post(
        `/me/upload-company-logo`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(uploadLogoSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (recall && recallFunction)
          skipStep ? recallFunction(skipStep) : recallFunction();
        if (shouldNavigate && navigate && pathname) {
          navigate(pathname);
        }
      } else if (response && response.data && response.data.errors) {
        dispatch(uploadLogoFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, uploadLogoFail, language);
    }
  };
export const clearUploadLogo = () => async (dispatch: AppDispatch) => {
  dispatch(uploadLogoReset());
};
const {
  getMeFail,
  getMeStart,
  getMeSuccess,
  getMeReset,
  updateMeFail,
  updateMeStart,
  updateMeSuccess,
  updateMeReset,
  updatePasswordFail,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordReset,
  uploadPhotoFail,
  uploadPhotoStart,
  uploadPhotoSuccess,
  uploadPhotoReset,
  uploadLogoFail,
  uploadLogoStart,
  uploadLogoSuccess,
  uploadLogoReset,
} = meSlice.actions;
export default meSlice.reducer;
