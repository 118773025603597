import { lazy } from "react";
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import AuthGuard from "../utils/route-guard/AuthGuard";

const FAQ = Loadable(lazy(() => import("../components/FAQ")));
const GetInTouch = Loadable(
  lazy(() => import("../views/dashboard/GetInTouch"))
);
const NoPagesFound = Loadable(
  lazy(() => import("../views/dashboard/ErrorStates/NoPagesFound"))
);
const NoConnection = Loadable(
  lazy(() => import("../views/dashboard/ErrorStates/NoConnection"))
);
const NoResultsFound = Loadable(
  lazy(() => import("../views/dashboard/ErrorStates/NoResultsFound"))
);

const AddonsRoutes = {
  path: "/dashboard",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard/faq",
      element: <FAQ />,
    },
    {
      path: "/dashboard/get-in-touch",
      element: <GetInTouch />,
    },
    {
      path: "/dashboard/*",
      element: <NoPagesFound />,
    },
    {
      path: "/dashboard/no-connection",
      element: <NoConnection />,
    },
    {
      path: "/dashboard/no-results-found",
      element: <NoResultsFound />,
    },
  ],
};

export default AddonsRoutes;
