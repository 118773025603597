import { notification } from "antd";
import { ReactNode } from "react";

export const Notification = (type: string, body: ReactNode) => {
  switch (type) {
    case "error":
      return notification.error({
        message: "Error",
        description: body,
        duration: 5,
        placement: "bottomLeft",
      });
    case "success":
      return notification.success({
        message: "Success",
        description: body,
        duration: 5,
        placement: "bottomLeft",
      });
    case "warning":
      return notification.warning({
        message: "Warn",
        description: body,
        duration: 5,
        placement: "bottomLeft",
      });
    default:
      return notification.info({
        message: "Info",
        description: body,
        duration: 5,
        placement: "bottomLeft",
      });
  }
};
