import { Avatar, Button, Dropdown, Skeleton, Space, Typography } from "antd";
import { DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { clearMe, getMe } from "../../../../store/me";
import avatar from "../../../../assets/images/user.svg";
import { useTranslation } from "react-i18next";
import { Roles } from "../../../../types";

export const ProfileSection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.me.getMe);
  const language = useSelector((state: RootState) => state.language.language);

  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem("lexolent-token");
  let decoded: { role: string; userId: string };
  let role;
  if (token) {
    decoded = jwtDecode(token);
    role = decoded.role;
  }

  useEffect(() => {
    return () => {
      dispatch(clearMe());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMe({ language }));
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      switch (true) {
        case currentUser.loading:
          setLoading(true);
          break;
        case currentUser.success:
          setLoading(false);
          break;
        case currentUser.error:
          setLoading(false);
          break;
        default:
          break;
      }
    }
  }, [currentUser]);

  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch(clearMe());
    localStorage.clear();
    navigate("/login");
  };
  const items = [
    role !== Roles.ADMIN
      ? {
          key: "1",
          label: (
            <Link
              to={`/dashboard/edit-profile`}
              style={{ fontFamily: "Montserrat-Regular" }}
            >
              <UserOutlined /> {t("profile")}
            </Link>
          ),
        }
      : null,
    {
      key: "2",
      label: (
        <Button type="link" onClick={handleLogOut} className="logout-button">
          <LogoutOutlined /> {t("logOut")}
        </Button>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
    >
      <div className="profile-wrapper">
        {loading ? (
          <Space.Compact block className="avatar">
            <Skeleton.Avatar shape="circle" active />
          </Space.Compact>
        ) : (
          <Avatar src={currentUser?.data?.photo || avatar} className="avatar" />
        )}

        <Typography.Text
          style={{ margin: 0, padding: 0 }}
          className="profile-name"
        >
          {loading ? (
            <Space.Compact block style={{ width: "125px", height: "44px" }}>
              <Skeleton.Input size="small" active />
            </Space.Compact>
          ) : (
            <>
              <p style={{ margin: 0, padding: 0 }}>
                {currentUser?.data?.firstName}
              </p>
              <p style={{ margin: 0, padding: 0 }} className="main f-12">
                {currentUser.loading ? (
                  <></>
                ) : currentUser?.data?.role === Roles.NETWORK_MEMBER ? (
                  t("networkMember")
                ) : currentUser?.data?.role === Roles.INVESTOR_MEMBER ? (
                  t("investor")
                ) : currentUser?.data?.role === Roles.NETWORK_INTRODUCER ? (
                  t("networkIntroducer")
                ) : currentUser?.data?.role === Roles.ASSOCIATE_MEMBER ? (
                  t("associateMember")
                ) : (
                  t("admin")
                )}
              </p>
            </>
          )}
        </Typography.Text>
        <DownOutlined className="profile-drop" />
      </div>
    </Dropdown>
  );
};
