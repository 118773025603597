import { ENV } from "../types";

const URL =
  process.env.REACT_APP_MODE === ENV.PRODUCTION
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_MODE === ENV.STAGING
    ? process.env.REACT_APP_STAGING_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const Config = {
  TOKEN: localStorage.getItem("lexolent-token"),
  BASE_URL: URL,
};

export default Config;
