import { lazy } from "react";

// project imports
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import AuthGuard from "../utils/route-guard/AuthGuard";

const Dashboard = Loadable(lazy(() => import("../views/dashboard")));
const Cases = Loadable(lazy(() => import("../views/dashboard/Cases")));
const OldCases = Loadable(lazy(() => import("../views/dashboard/Cases/Old")));
const LexolentCases = Loadable(
  lazy(() => import("../views/dashboard/LexolentCases"))
);
const MarketplaceCases = Loadable(
  lazy(() => import("../views/dashboard/MarketplaceCases"))
);
const HousefundCases = Loadable(
  lazy(() => import("../views/dashboard/HousefundCases"))
);
const FeaturedMembers = Loadable(
  lazy(() => import("../views/dashboard/FeaturedMembers"))
);
const CreateCases = Loadable(
  lazy(() => import("../views/dashboard/Cases/Create"))
);
const UserLeads = Loadable(lazy(() => import("../views/dashboard/UserLeads")));
const Profile = Loadable(lazy(() => import("../views/dashboard/Profile")));
// const Tags = Loadable(lazy(() => import("../views/dashboard/Settings/Tags")));
const Region = Loadable(
  lazy(() => import("../views/dashboard/Settings/Region"))
);
const CaseTypes = Loadable(
  lazy(() => import("../views/dashboard/Settings/CaseTypes"))
);
const ClaimTypes = Loadable(
  lazy(() => import("../views/dashboard/Settings/ClaimTypes"))
);
const Currency = Loadable(
  lazy(() => import("../views/dashboard/Settings/Currency"))
);
const JurisdictionalRegion = Loadable(
  lazy(() => import("../views/dashboard/Settings/JurisdictionalRegion"))
);
const Forum = Loadable(lazy(() => import("../views/dashboard/Settings/Forum")));
const Specialization = Loadable(
  lazy(() => import("../views/dashboard/Settings/Specializations"))
);
const CaseBids = Loadable(lazy(() => import("../views/dashboard/Bids")));
const Managment = Loadable(
  lazy(() => import("../views/dashboard/CaseBidsManagment"))
);
const LexConnect = Loadable(
  lazy(() => import("../views/dashboard/LexConnect"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/dashboard",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/dashboard/cases",
      element: <Cases />,
    },
    {
      path: "/dashboard/old-cases",
      element: <OldCases />,
    },
    {
      path: "/dashboard/lexolent-cases",
      element: <LexolentCases />,
    },
    {
      path: "/dashboard/marketplace-cases",
      element: <MarketplaceCases />,
    },
    {
      path: "/dashboard/house-fund-cases",
      element: <HousefundCases />,
    },
    {
      path: "/dashboard/cases/create",
      element: <CreateCases />,
    },
    {
      path: "/dashboard/cases/edit/:id",
      element: <CreateCases />,
    },
    {
      path: "/dashboard/featured-members",
      element: <FeaturedMembers />,
    },
    {
      path: "/dashboard/user-leads",
      element: <UserLeads />,
    },
    {
      path: "/dashboard/profile/:id",
      element: <Profile />,
    },
    // {
    //   path: "/dashboard/tags",
    //   element: <Tags />,
    // },
    {
      path: "/dashboard/claim-jurisdictions",
      element: <Region />,
    },
    {
      path: "/dashboard/case-types",
      element: <ClaimTypes />,
    },
    {
      path: "/dashboard/claim-types",
      element: <CaseTypes />,
    },
    {
      path: "/dashboard/currency",
      element: <Currency />,
    },
    {
      path: "/dashboard/regions",
      element: <JurisdictionalRegion />,
    },
    {
      path: "/dashboard/forums",
      element: <Forum />,
    },
    {
      path: "/dashboard/specializations",
      element: <Specialization />,
    },
    {
      path: "/dashboard/case-bids",
      element: <CaseBids />,
    },
    {
      path: "/dashboard/case-bids/management/:id",
      element: <Managment />,
    },
    {
      path: "/dashboard/lexconnect",
      element: <LexConnect />,
    },
  ],
};

export default MainRoutes;
