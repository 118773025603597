import { Layout, Typography } from "antd";
import "../../style/mainlayout.scss";
import { HeaderSection } from "./Header";
import { MenuSection } from "./Menu";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CustomizationButton } from "../../components/ui/buttons/Customization";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { rtl } from "../../types";

const { Content, Sider, Footer } = Layout;

const MainLayout = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const direction = useSelector(
    ({ language }: RootState) => language.direction
  );

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (
      location.pathname === "/dashboard/cases/create" ||
      location.pathname.includes("/dashboard/cases/edit")
    ) {
      setCollapsed(true);
    }
  }, [location]);

  return (
    <Layout>
      <HeaderSection />
      <Layout className="main-sider-content">
        <Sider className="main-sider" collapsed={collapsed}>
          <MenuSection
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
          />
        </Sider>
        <Content
          className={`main-content ${
            collapsed
              ? `${
                  direction === rtl
                    ? "collapsed-right-sidebar"
                    : "collapsed-sidebar"
                }`
              : `${
                  direction === rtl
                    ? "full-width-right-sidebar"
                    : "full-width-sidebar"
                }`
          }`}
        >
          <Outlet />
          <CustomizationButton />
        </Content>
        <Footer
          className={`main-footer ${
            collapsed
              ? `${
                  direction === rtl
                    ? "collapsed-footer-right-sidebar border-right"
                    : "collapsed-sidebar border-left"
                } footer-c-width`
              : `${
                  direction === rtl
                    ? "full-width-footer-right-sidebar border-right"
                    : "full-width-sidebar border-left"
                } footer-width`
          }`}
        >
          <div className="link-wrapper">
            <Typography.Text className="footer-element">
              © {t("lexolentYear")}
            </Typography.Text>
            <Typography.Text className="footer-element">
              <a
                href="https://www.lexolent.com/terms-conditions"
                target="_blank"
                className="footer-element"
                rel="noopener noreferrer"
              >
                {t("termsOfUse")}
              </a>
            </Typography.Text>
            <Typography.Text className="footer-element">
              <a
                href="https://www.lexolent.com/privacy-policy"
                target="_blank"
                className="footer-element"
                rel="noopener noreferrer"
              >
                {t("privacyPolicy")}
              </a>
            </Typography.Text>
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
