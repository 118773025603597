import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const claimTypesSlice = createSlice({
  name: "claimTypes",
  initialState: {
    getAll: { ...initialState },
    getOne: { ...initialState },
    create: { ...initialState },
    update: { ...initialState },
    delete: { ...initialState },
  },
  reducers: {
    getAllClaimTypeStart(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = true;
      state.getAll.success = false;
    },
    getAllClaimTypeSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = true;
    },
    getAllClaimTypeFail(state, action) {
      state.getAll.data = null;
      state.getAll.errorMessage = action.payload;
      state.getAll.error = true;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllClaimTypesReset(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneClaimTypestart(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = true;
      state.getOne.success = false;
    },
    getOneClaimTypesuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = true;
    },
    getOneClaimTypeFail(state, action) {
      state.getOne.data = null;
      state.getOne.errorMessage = action.payload;
      state.getOne.error = true;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneClaimTypeReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    createClaimTypestart(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = true;
      state.create.success = false;
    },
    createClaimTypesuccess(state, action) {
      state.create.data = action.payload;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = true;
    },
    createClaimTypeFail(state, action) {
      state.create.data = null;
      state.create.errorMessage = action.payload;
      state.create.error = true;
      state.create.loading = false;
      state.create.success = false;
    },
    createClaimTypeReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateClaimTypestart(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = true;
      state.update.success = false;
    },
    updateClaimTypesuccess(state, action) {
      state.update.data = action.payload;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = true;
    },
    updateClaimTypeFail(state, action) {
      state.update.data = null;
      state.update.errorMessage = action.payload;
      state.update.error = true;
      state.update.loading = false;
      state.update.success = false;
    },
    updateClaimTypeReset(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = false;
    },
    deleteClaimTypestart(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = true;
      state.delete.success = false;
    },
    deleteClaimTypesuccess(state, action) {
      state.delete.data = action.payload;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = true;
    },
    deleteClaimTypeFail(state, action) {
      state.delete.data = null;
      state.delete.errorMessage = action.payload;
      state.delete.error = true;
      state.delete.loading = false;
      state.delete.success = false;
    },
    deleteClaimTypesReset(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = false;
    },
  },
});
export const getClaimTypes =
  (
    payload: {
      page?: number;
      pageSize?: number;
      search?: string;
      sortOrder?: string;
      select?: string;
      pagination?: boolean;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllClaimTypeStart());
      const response = await APIClient.get("/claimtypes", { params: payload });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllClaimTypeSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllClaimTypeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllClaimTypeFail, language);
    }
  };
export const clearClaimTypes = () => async (dispatch: AppDispatch) => {
  dispatch(getAllClaimTypesReset());
};
export const getOneClaimType =
  (payload: { claimTypeId: string }, options: { language: string }) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getOneClaimTypestart());
      const { claimTypeId } = payload;
      const response = await APIClient.get(`/claimtypes/${claimTypeId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getOneClaimTypesuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneClaimTypeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneClaimTypeFail, language);
    }
  };
export const clearClaimType = () => async (dispatch: AppDispatch) => {
  dispatch(getOneClaimTypeReset());
};
export const createClaimType =
  (
    payload: { name: string; translations: { ar: string } },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(createClaimTypestart());
      const response = await APIClient.post("/claimtypes", payload);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(createClaimTypesuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(createClaimTypeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createClaimTypeFail, language);
    }
  };
export const clearCreateClaimType = () => async (dispatch: AppDispatch) => {
  dispatch(createClaimTypeReset());
};
export const updateClaimType =
  (
    payload: {
      claimTypeId: string;
      name?: string;
      isActive?: boolean;
      translations?: { ar: string };
    },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(updateClaimTypestart());
      const { claimTypeId } = payload;
      const body: any = payload;
      delete body["claimTypeId"];
      const response = await APIClient.patch(
        `/claimtypes/${claimTypeId}`,
        body
      );
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(updateClaimTypesuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(updateClaimTypeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateClaimTypeFail, language);
    }
  };
export const clearUpdateClaimType = () => async (dispatch: AppDispatch) => {
  dispatch(updateClaimTypeReset());
};
export const deleteClaimType =
  (
    payload: { claimTypeId: string },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(deleteClaimTypestart());
      const { claimTypeId } = payload;
      const { recall, onClose, onSuccessMessage } = options;
      const response = await APIClient.delete(`/claimtypes/${claimTypeId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(deleteClaimTypesuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(deleteClaimTypeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, deleteClaimTypeFail, language);
    }
  };
export const clearDeleteClaimType = () => async (dispatch: AppDispatch) => {
  dispatch(deleteClaimTypesReset());
};
const {
  getAllClaimTypeFail,
  getAllClaimTypeStart,
  getAllClaimTypeSuccess,
  getAllClaimTypesReset,
  getOneClaimTypeFail,
  getOneClaimTypestart,
  getOneClaimTypesuccess,
  getOneClaimTypeReset,
  createClaimTypeFail,
  createClaimTypestart,
  createClaimTypesuccess,
  createClaimTypeReset,
  updateClaimTypeFail,
  updateClaimTypestart,
  updateClaimTypesuccess,
  updateClaimTypeReset,
  deleteClaimTypeFail,
  deleteClaimTypestart,
  deleteClaimTypesuccess,
  deleteClaimTypesReset,
} = claimTypesSlice.actions;
export default claimTypesSlice.reducer;
