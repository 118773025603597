import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row } from "antd";
import { NavTypes } from "../../../types";

const { Header } = Layout;
interface Nav {
  key: string;
  title: string;
  path: string;
  comingSoon: boolean;
}
interface Props {
  isMenuOpen: boolean;
  nav: Nav[];
  handleClick: (nav: Nav) => void;
  toggleMenu: () => void;
}
export const HeaderSection = ({
  isMenuOpen,
  nav,
  handleClick,
  toggleMenu,
}: Props) => {
  return (
    <Header className="lexhub-header">
      <Row className="nav-wrapper" justify="center" align="middle">
        {nav.map((e) =>
          e.key === NavTypes.LEXHUB ? (
            <Col key={e.key}>
              <Row>
                <Button
                  onClick={() => handleClick(e)}
                  className="f-20 l-24 nav-main-item"
                  type="link"
                >
                  {e.title}
                </Button>
              </Row>
            </Col>
          ) : (
            <Col key={e.key}>
              <Row>
                <Button
                  className="nav-item f-20 f-400 l-24"
                  onClick={() => handleClick(e)}
                  type="link"
                >
                  {e.title}
                </Button>
              </Row>
            </Col>
          )
        )}
      </Row>
      <Row className="sm-nav-wrapper" justify="end" align="middle">
        <Col>
          <Row>
            <Button
              type="link"
              onClick={toggleMenu}
              className="nav-button f-20"
            >
              <CloseOutlined className={`${isMenuOpen ? "show" : "hide"}`} />
              <MenuOutlined className={`${isMenuOpen ? "hide" : "show"}`} />
            </Button>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};
