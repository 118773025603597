import { useRoutes } from "react-router-dom";

import AuthRoutes from "./AuthRoutes";
import MainRoutes from "./MainRoutes";
import jwtDecode from "jwt-decode";
import AssociateRoutes from "./AssociateRoutes";
import NetworkMemberRoutes from "./NetworkMemberRoutes";
import NetworkIntroducerRoutes from "./NetworkIntroducerRoutes";
import AddonsRoutes from "./AddonsRoutes";
import InvestorRoutes from "./InvestorRoutes";
import LoginRoutes from "./LoginRoutes";
import HomeRoutes from "./HomeRoutes";
import { Roles } from "../types";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const token = localStorage.getItem("lexolent-token");
  let decoded: { role: any };
  let role: string;
  if (token) {
    decoded = jwtDecode(token);
    role = decoded.role;
  }

  const routes = () => {
    if (role === Roles.ADMIN) {
      return MainRoutes;
    } else if (role === Roles.INVESTOR_MEMBER) {
      return InvestorRoutes;
    } else if (role === Roles.NETWORK_MEMBER) {
      return NetworkMemberRoutes;
    } else if (role === Roles.NETWORK_INTRODUCER) {
      return NetworkIntroducerRoutes;
    } else {
      return AssociateRoutes;
    }
  };
  return useRoutes([
    LoginRoutes,
    AuthRoutes,
    HomeRoutes,
    AddonsRoutes,
    routes(),
  ]);
}
