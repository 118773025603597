// ==============================|| LOADER ||============================== //

import { LoadingOutlined } from "@ant-design/icons";
import { ProgressProps, Spin } from "antd";
import "../style/uicomponents.scss";

export interface LoaderProps extends ProgressProps {}

const Loader = () => (
  <div className="loader">
    <Spin indicator={<LoadingOutlined className="icon" spin />} />
  </div>
);

export default Loader;
