import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import APIClient from "../../services/ApiClient";
import { Notification } from "../../utils/Notification";
import { AppDispatch } from "..";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const jurisdictionalRegionSlice = createSlice({
  name: "jurisdictionalRegion",
  initialState: {
    getAll: { ...initialState },
    getOne: { ...initialState },
    create: { ...initialState },
    update: { ...initialState },
    delete: { ...initialState },
  },
  reducers: {
    getAllJurisdictionalRegionStart(state) {
      state.getAll.data = null;
      state.getAll.loading = true;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.success = false;
    },
    getAllJurisdictionalRegionSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.loading = false;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.success = true;
    },
    getAllJurisdictionalRegionFail(state, action: PayloadAction<string>) {
      state.getAll.data = null;
      state.getAll.error = true;
      state.getAll.errorMessage = action.payload;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllJurisdictionalRegionReset(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneJurisdictionalRegionStart(state) {
      state.getOne.data = null;
      state.getOne.loading = true;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.success = false;
    },
    getOneJurisdictionalRegionSuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.loading = false;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.success = true;
    },
    getOneJurisdictionalRegionFail(state, action: PayloadAction<string>) {
      state.getOne.data = null;
      state.getOne.error = true;
      state.getOne.errorMessage = action.payload;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneJurisdictionalRegionReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    createJurisdictionalRegionStart(state) {
      state.create.data = null;
      state.create.loading = true;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.success = false;
    },
    createJurisdictionalRegionSuccess(state, action) {
      state.create.data = action.payload;
      state.create.loading = false;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.success = true;
    },
    createJurisdictionalRegionFail(state, action: PayloadAction<string>) {
      state.create.data = null;
      state.create.error = true;
      state.create.errorMessage = action.payload;
      state.create.loading = false;
      state.create.success = false;
    },
    createJurisdictionalRegionReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateJurisdictionalRegionStart(state) {
      state.update.loading = true;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.success = false;
    },
    updateJurisdictionalRegionSuccess(state, action) {
      state.update.data = { docs: [action.payload], totalDocs: 1 };
      state.update.loading = false;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.success = true;
    },
    updateJurisdictionalRegionFail(
      state,
      action: PayloadAction<string | undefined>
    ) {
      state.update.error = true;
      state.update.errorMessage = action.payload || null;
      state.update.loading = false;
      state.update.success = false;
    },
    updateJurisdictionalRegionReset(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = false;
    },
    deleteJurisdictionalRegionStart(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = true;
      state.delete.success = false;
    },
    deleteJurisdictionalRegionSuccess(state, action) {
      state.delete.data = action.payload;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = true;
    },
    deleteJurisdictionalRegionFail(state, action) {
      state.delete.data = null;
      state.delete.errorMessage = action.payload;
      state.delete.error = true;
      state.delete.loading = false;
      state.delete.success = false;
    },
    deleteJurisdictionalRegionReset(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = false;
    },
  },
});
export const getAllJurisdictionalRegion =
  (
    payload: { page?: number; pageSize?: number; pagination?: boolean },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllJurisdictionalRegionStart());
      const response = await APIClient.get("/regions", {
        params: payload,
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllJurisdictionalRegionSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllJurisdictionalRegionFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllJurisdictionalRegionFail, language);
    }
  };
export const clearJurisdictionalRegion =
  () => async (dispatch: AppDispatch) => {
    dispatch(getAllJurisdictionalRegionReset());
  };
export const getOneJurisdictionalRegion =
  (
    payload: { jurisdictionalRegionId: string },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getOneJurisdictionalRegionStart());
      const { jurisdictionalRegionId } = payload;
      const response = await APIClient.get(
        `/regions/${jurisdictionalRegionId}`
      );
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getOneJurisdictionalRegionSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneJurisdictionalRegionFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneJurisdictionalRegionFail, language);
    }
  };
export const clearOneJurisdictionalRegion =
  () => async (dispatch: AppDispatch) => {
    dispatch(getOneJurisdictionalRegionReset());
  };
export const createJurisdictionalRegion =
  (
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(createJurisdictionalRegionStart());
      const response = await APIClient.post("/regions", payload);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(createJurisdictionalRegionSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(createJurisdictionalRegionFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createJurisdictionalRegionFail, language);
    }
  };
export const clearCreateJurisdictionalRegion =
  () => async (dispatch: AppDispatch) => {
    dispatch(createJurisdictionalRegionReset());
  };
export const updateJurisdictionalRegion =
  (
    jurisdictionalRegionId: string,
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(updateJurisdictionalRegionStart());
      const response = await APIClient.patch(
        `/regions/${jurisdictionalRegionId}`,
        payload
      );
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(updateJurisdictionalRegionSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(updateJurisdictionalRegionFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateJurisdictionalRegionFail, language);
    }
  };
export const clearUpdateJurisdictionalRegion =
  () => async (dispatch: AppDispatch) => {
    dispatch(updateJurisdictionalRegionReset());
  };
export const deleteJurisdictionalRegion =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(deleteJurisdictionalRegionStart());
      const { jurisdictionalRegionId } = payload;
      const response = await APIClient.patch(
        `/regions/${jurisdictionalRegionId}`,
        {
          isActive: false,
        }
      );
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(deleteJurisdictionalRegionSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(deleteJurisdictionalRegionFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      if (
        error &&
        error.response?.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "string"
      ) {
        dispatch(deleteJurisdictionalRegionFail(error.response.data.errors));
        Notification("error", error.response.data.errors);
      } else if (
        Object.prototype.toString.call(error.response.data.errors) ===
        "[object Object]"
      ) {
        const message = Object.keys(error.response.data.errors)
          .map((key) => error.response.data.errors[key])
          .join(".\n");

        dispatch(deleteJurisdictionalRegionFail(message));
        Notification("error", message);
      } else {
        dispatch(deleteJurisdictionalRegionFail(error.message));
        Notification("error", error.message);
      }
    }
  };
export const clearDeleteJurisdictionalRegion =
  () => async (dispatch: AppDispatch) => {
    dispatch(deleteJurisdictionalRegionReset());
  };

export const {
  getAllJurisdictionalRegionStart,
  getAllJurisdictionalRegionSuccess,
  getAllJurisdictionalRegionFail,
  getAllJurisdictionalRegionReset,
  getOneJurisdictionalRegionStart,
  getOneJurisdictionalRegionSuccess,
  getOneJurisdictionalRegionFail,
  getOneJurisdictionalRegionReset,
  createJurisdictionalRegionStart,
  createJurisdictionalRegionSuccess,
  createJurisdictionalRegionFail,
  createJurisdictionalRegionReset,
  updateJurisdictionalRegionStart,
  updateJurisdictionalRegionSuccess,
  updateJurisdictionalRegionFail,
  updateJurisdictionalRegionReset,
  deleteJurisdictionalRegionStart,
  deleteJurisdictionalRegionSuccess,
  deleteJurisdictionalRegionFail,
  deleteJurisdictionalRegionReset,
} = jurisdictionalRegionSlice.actions;

export default jurisdictionalRegionSlice.reducer;
