import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const caseTypesSlice = createSlice({
  name: "caseTypes",
  initialState: {
    getAll: { ...initialState },
    getOne: { ...initialState },
    create: { ...initialState },
    update: { ...initialState },
    delete: { ...initialState },
  },
  reducers: {
    getAllCaseTypesStart(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = true;
      state.getAll.success = false;
    },
    getAllCaseTypesSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = true;
    },
    getAllCaseTypesFail(state, action) {
      state.getAll.data = null;
      state.getAll.errorMessage = action.payload;
      state.getAll.error = true;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllCaseTypesReset(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneCaseTypeStart(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = true;
      state.getOne.success = false;
    },
    getOneCaseTypeSuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = true;
    },
    getOneCaseTypeFail(state, action) {
      state.getOne.data = null;
      state.getOne.errorMessage = action.payload;
      state.getOne.error = true;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneCaseTypeReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    createCaseTypeStart(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = true;
      state.create.success = false;
    },
    createCaseTypeSuccess(state, action) {
      state.create.data = action.payload;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = true;
    },
    createCaseTypeFail(state, action) {
      state.create.data = null;
      state.create.errorMessage = action.payload;
      state.create.error = true;
      state.create.loading = false;
      state.create.success = false;
    },
    createCaseTypeReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateCaseTypeStart(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = true;
      state.update.success = false;
    },
    updateCaseTypeSuccess(state, action) {
      state.update.data = action.payload;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = true;
    },
    updateCaseTypeFail(state, action) {
      state.update.data = null;
      state.update.errorMessage = action.payload;
      state.update.error = true;
      state.update.loading = false;
      state.update.success = false;
    },
    updateCaseTypeReset(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = false;
    },
    deleteCaseTypeStart(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = true;
      state.delete.success = false;
    },
    deleteCaseTypeSuccess(state, action) {
      state.delete.data = action.payload;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = true;
    },
    deleteCaseTypeFail(state, action) {
      state.delete.data = null;
      state.delete.errorMessage = action.payload;
      state.delete.error = true;
      state.delete.loading = false;
      state.delete.success = false;
    },
    deleteCaseTypeReset(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = false;
    },
  },
});
export const getCaseTypes =
  (
    payload: {
      page?: number;
      pageSize?: number;
      search?: string;
      sortOrder?: string;
      select?: string;
      pagination?: boolean;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllCaseTypesStart());
      const response = await APIClient.get("/casetypes", { params: payload });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllCaseTypesSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllCaseTypesFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllCaseTypesFail, language);
    }
  };
export const clearCaseTypes = () => async (dispatch: AppDispatch) => {
  dispatch(getAllCaseTypesReset());
};
export const getOneCaseType =
  (payload: { caseTypeId: string }, options: { language: string }) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getOneCaseTypeStart());
      const { caseTypeId } = payload;
      const response = await APIClient.get(`/casetypes/${caseTypeId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getOneCaseTypeSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneCaseTypeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneCaseTypeFail, language);
    }
  };
export const clearCaseType = () => async (dispatch: AppDispatch) => {
  dispatch(getOneCaseTypeReset());
};
export const createCaseType =
  (
    payload: {
      name: string;
      translations: { ar: string };
      claimTypeId: string;
    },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(createCaseTypeStart());
      const response = await APIClient.post("/casetypes", payload);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(createCaseTypeSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(createCaseTypeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createCaseTypeFail, language);
    }
  };
export const clearCreateCaseType = () => async (dispatch: AppDispatch) => {
  dispatch(createCaseTypeReset());
};
export const updateCaseType =
  (
    payload: {
      caseTypeId: string;
      name?: string;
      isActive?: boolean;
      translations?: { ar: string };
      claimTypeId?: string;
    },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(updateCaseTypeStart());
      const { caseTypeId } = payload;
      const body: any = payload;
      delete body["caseTypeId"];
      const response = await APIClient.patch(`/casetypes/${caseTypeId}`, body);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(updateCaseTypeSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(updateCaseTypeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateCaseTypeFail, language);
    }
  };
export const clearUpdateCaseType = () => async (dispatch: AppDispatch) => {
  dispatch(updateCaseTypeReset());
};
export const deleteCaseType =
  (
    payload: { caseTypeId: string },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(deleteCaseTypeStart());
      const { caseTypeId } = payload;
      const response = await APIClient.delete(`/casetypes/${caseTypeId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(deleteCaseTypeSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(deleteCaseTypeFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, deleteCaseTypeFail, language);
    }
  };
export const clearDeleteCaseType = () => async (dispatch: AppDispatch) => {
  dispatch(deleteCaseTypeReset());
};
const {
  getAllCaseTypesFail,
  getAllCaseTypesStart,
  getAllCaseTypesSuccess,
  getAllCaseTypesReset,
  getOneCaseTypeFail,
  getOneCaseTypeStart,
  getOneCaseTypeSuccess,
  getOneCaseTypeReset,
  createCaseTypeFail,
  createCaseTypeStart,
  createCaseTypeSuccess,
  createCaseTypeReset,
  updateCaseTypeFail,
  updateCaseTypeStart,
  updateCaseTypeSuccess,
  updateCaseTypeReset,
  deleteCaseTypeFail,
  deleteCaseTypeStart,
  deleteCaseTypeSuccess,
  deleteCaseTypeReset,
} = caseTypesSlice.actions;
export default caseTypesSlice.reducer;
