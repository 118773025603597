import { lazy } from "react";

// project imports
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import AuthGuard from "../utils/route-guard/AuthGuard";

const InvestorDashboard = Loadable(
  lazy(() => import("../views/dashboard/Users/Investor"))
);
const FeaturedMembers = Loadable(
  lazy(() => import("../views/dashboard/FeaturedMembers"))
);
const EditProfile = Loadable(
  lazy(() => import("../views/dashboard/Edit-Profile"))
);
const SavedCases = Loadable(
  lazy(() => import("../views/dashboard/SavedCases"))
);
const FAQ = Loadable(lazy(() => import("../components/FAQ")));
const MyBids = Loadable(lazy(() => import("../views/dashboard/MyBids")));
const FundedCases = Loadable(
  lazy(() => import("../views/dashboard/FundedCases"))
);
const DeclinedCases = Loadable(
  lazy(() => import("../views/dashboard/DeclinedCases"))
);
const LexConnect = Loadable(
  lazy(() => import("../views/dashboard/LexConnect"))
);
const NewCases = Loadable(lazy(() => import("../views/dashboard/NewCases")));

// ==============================|| USER ROUTING ||============================== //

const InvestorRoutes = {
  path: "/dashboard",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <InvestorDashboard />,
    },
    {
      path: "/dashboard/edit-profile",
      element: <EditProfile />,
    },
    {
      path: "/dashboard/featured-members",
      element: <FeaturedMembers />,
    },
    {
      path: "/dashboard/lexconnect",
      element: <LexConnect />,
    },
    {
      path: "/dashboard/saved-cases",
      element: <SavedCases />,
    },
    {
      path: "/dashboard/faq",
      element: <FAQ />,
    },
    {
      path: "/dashboard/my-bids",
      element: <MyBids />,
    },
    {
      path: "/dashboard/funded-cases",
      element: <FundedCases />,
    },
    {
      path: "/dashboard/declined-cases",
      element: <DeclinedCases />,
    },
    {
      path: "/dashboard/new-cases",
      element: <NewCases />,
    },
  ],
};

export default InvestorRoutes;
