import { lazy } from "react";

// project imports
import Loadable from "../components/Loadable";
import AuthGuard from "../utils/route-guard/AuthGuard";
import LexhubLayout from "../layouts/LexhubLayout";

// login routing
const Lexhub = Loadable(lazy(() => import("../views/lexhub")));

// ==============================|| HOME ROUTING ||============================== //

const HomeRoutes = {
  path: "/lexhub",
  element: (
    <AuthGuard>
      <LexhubLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/lexhub",
      element: <Lexhub />,
    },
  ],
};

export default HomeRoutes;
