import { Col, Layout, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;

export const FooterSection = () => {
  const { t } = useTranslation();
  return (
    <Footer className="lexhub-footer">
      <Row justify="center" align="middle">
        <Row justify="center" align="middle" gutter={[30, 30]}>
          <Col xs={24}>
            <Row justify="center">
              <Typography.Text className="f-16 light">
                {t("disclaimer")}
              </Typography.Text>
            </Row>
          </Col>
          <Col xs={24}>
            <Row justify="center">
              <Typography.Text className="f-16 text-center semi-bold">
                {t("copyright")}
              </Typography.Text>
              <Typography.Text className="f-16 text-center semi-bold">
                <a
                  style={{ padding: "0px 5px" }}
                  className="f-16 semi-bold"
                  href="https://www.lexolent.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("PrivacyPolicy")}
                </a>
              </Typography.Text>
              <Typography.Text className="f-16 text-center semi-bold">
                <a
                  className="f-16 semi-bold"
                  href="https://www.lexolent.com/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("termsConditions")}
                </a>
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      </Row>
    </Footer>
  );
};
