import { lazy } from "react";
import { Outlet } from "react-router-dom";

// project imports
import Loadable from "../components/Loadable";

// login routing
const AuthLogin = Loadable(lazy(() => import("../views/auth/Login")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <Outlet />,
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "/login",
      element: <AuthLogin />,
    },
  ],
};

export default LoginRoutes;
