import { Menu } from "antd";
import {
  admin,
  networkMember,
  investorMember,
  networkIntroducer,
  associateMember,
} from "./Items";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Roles } from "../../../types";

interface Props {
  collapsed: boolean;
  toggleCollapsed: () => void;
}

export const MenuSection = ({ collapsed, toggleCollapsed }: Props) => {
  const [path, setPath] = useState<string>("/dashboard");
  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = useSelector((state: RootState) => state.me.getMe);

  const token = localStorage.getItem("lexolent-token");

  let decoded: any;
  let role: string;
  if (token) {
    decoded = jwtDecode(token);
    role = decoded.role;
  }

  const items = () => {
    if (role === Roles.ADMIN) {
      return admin;
    } else if (role === Roles.INVESTOR_MEMBER) {
      return investorMember;
    } else if (role === Roles.NETWORK_MEMBER) {
      return networkMember;
    } else if (role === Roles.NETWORK_INTRODUCER) {
      return networkIntroducer;
    } else {
      return associateMember;
    }
  };

  const handleDisable = () => {
    if (
      role !== Roles.ADMIN &&
      role !== Roles.INVESTOR_MEMBER &&
      currentUser &&
      currentUser.data &&
      (!currentUser.data?.featuredUser?.bio ||
        !currentUser?.data?.photo ||
        !currentUser?.data?.companyLogo)
    ) {
      return true;
    } else if (
      role !== Roles.ADMIN &&
      role === Roles.INVESTOR_MEMBER &&
      currentUser &&
      currentUser.data &&
      !currentUser?.data?.isHouseFundMember &&
      // !currentUser?.data?.tag?.length ||
      (!currentUser?.data?.claimType?.length ||
        !currentUser?.data?.region?.length)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/cases/create") {
      setPath("/dashboard/cases");
    } else setPath(location.pathname);
  }, [location]);
  return (
    <>
      <Menu
        className="menu"
        defaultSelectedKeys={[path]}
        selectedKeys={[path]}
        mode="inline"
        theme="dark"
        items={items()}
        disabled={handleDisable()}
        onClick={(e) => navigate(e.key)}
      />
      <div className="collapse-icon">
        {!collapsed ? (
          <MenuFoldOutlined
            style={{ color: "white" }}
            onClick={toggleCollapsed}
          />
        ) : (
          <MenuUnfoldOutlined
            style={{ color: "white" }}
            onClick={toggleCollapsed}
          />
        )}
      </div>
    </>
  );
};
