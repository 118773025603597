import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const bidsSlice = createSlice({
  name: "bids",
  initialState: {
    getAll: { ...initialState, dataDocs: [] },
    getLatest: { ...initialState, dataDocs: [] },
    getOne: { ...initialState },
    getStats: { ...initialState },
    create: { ...initialState },
    updateOne: { ...initialState },
    updateCaseBid: { ...initialState },
  },
  reducers: {
    getAllBidsStart(state) {
      state.getAll.data = null;
      state.getAll.dataDocs = state.getAll.dataDocs;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = true;
      state.getAll.success = false;
    },
    getAllBidsSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.dataDocs = state.getAll.dataDocs.concat(
        action?.payload?.bids
      );
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = true;
    },
    getAllBidsFail(state, action) {
      state.getAll.data = null;
      state.getAll.dataDocs = [];
      state.getAll.errorMessage = action.payload;
      state.getAll.error = true;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllBidsReset(state) {
      state.getAll.data = null;
      state.getAll.dataDocs = [];
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getLatestBidsStart(state) {
      state.getLatest.data = null;
      state.getLatest.dataDocs = state.getLatest.dataDocs;
      state.getLatest.error = false;
      state.getLatest.errorMessage = null;
      state.getLatest.loading = true;
      state.getLatest.success = false;
    },
    getLatestBidsSuccess(state, action) {
      state.getLatest.data = action.payload;
      state.getLatest.dataDocs = state.getLatest.dataDocs.concat(
        action?.payload?.bids
      );
      state.getLatest.error = false;
      state.getLatest.errorMessage = null;
      state.getLatest.loading = false;
      state.getLatest.success = true;
    },
    getLatestBidsFail(state, action) {
      state.getLatest.data = null;
      state.getLatest.dataDocs = [];
      state.getLatest.errorMessage = action.payload;
      state.getLatest.error = true;
      state.getLatest.loading = false;
      state.getLatest.success = false;
    },
    getLatestBidsReset(state) {
      state.getLatest.data = null;
      state.getLatest.dataDocs = [];
      state.getLatest.error = false;
      state.getLatest.errorMessage = null;
      state.getLatest.loading = false;
      state.getLatest.success = false;
    },
    getOneBidStart(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = true;
      state.getOne.success = false;
    },
    getOneBidSuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = true;
    },
    getOneBidFail(state, action) {
      state.getOne.data = null;
      state.getOne.errorMessage = action.payload;
      state.getOne.error = true;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneBidReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getBidStatsStart(state) {
      state.getStats.data = null;
      state.getStats.error = false;
      state.getStats.errorMessage = null;
      state.getStats.loading = true;
      state.getStats.success = false;
    },
    getBidStatsSuccess(state, action) {
      state.getStats.data = action.payload;
      state.getStats.error = false;
      state.getStats.errorMessage = null;
      state.getStats.loading = false;
      state.getStats.success = true;
    },
    getBidStatsFail(state, action) {
      state.getStats.data = null;
      state.getStats.errorMessage = action.payload;
      state.getStats.error = true;
      state.getStats.loading = false;
      state.getStats.success = false;
    },
    getBidStatsReset(state) {
      state.getStats.data = null;
      state.getStats.error = false;
      state.getStats.errorMessage = null;
      state.getStats.loading = false;
      state.getStats.success = false;
    },
    createBidStart(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = true;
      state.create.success = false;
    },
    createBidSuccess(state, action) {
      state.create.data = action.payload;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = true;
    },
    createBidFail(state, action) {
      state.create.data = null;
      state.create.errorMessage = action.payload;
      state.create.error = true;
      state.create.loading = false;
      state.create.success = false;
    },
    createBidReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateBidStart(state) {
      state.updateOne.data = null;
      state.updateOne.error = false;
      state.updateOne.errorMessage = null;
      state.updateOne.loading = true;
      state.updateOne.success = false;
    },
    updateBidSuccess(state, action) {
      state.updateOne.data = action.payload;
      state.updateOne.error = false;
      state.updateOne.errorMessage = null;
      state.updateOne.loading = false;
      state.updateOne.success = true;
    },
    updateBidFail(state, action) {
      state.updateOne.data = null;
      state.updateOne.errorMessage = action.payload;
      state.updateOne.error = true;
      state.updateOne.loading = false;
      state.updateOne.success = false;
    },
    updateBidReset(state) {
      state.updateOne.data = null;
      state.updateOne.error = false;
      state.updateOne.errorMessage = null;
      state.updateOne.loading = false;
      state.updateOne.success = false;
    },
    updateCaseBidStart(state) {
      state.updateCaseBid.data = null;
      state.updateCaseBid.error = false;
      state.updateCaseBid.errorMessage = null;
      state.updateCaseBid.loading = true;
      state.updateCaseBid.success = false;
    },
    updateCaseBidSuccess(state, action) {
      state.updateCaseBid.data = action.payload;
      state.updateCaseBid.error = false;
      state.updateCaseBid.errorMessage = null;
      state.updateCaseBid.loading = false;
      state.updateCaseBid.success = true;
    },
    updateCaseBidFail(state, action) {
      state.updateCaseBid.data = null;
      state.updateCaseBid.errorMessage = action.payload;
      state.updateCaseBid.error = true;
      state.updateCaseBid.loading = false;
      state.updateCaseBid.success = false;
    },
    updateCaseBidReset(state) {
      state.updateCaseBid.data = null;
      state.updateCaseBid.error = false;
      state.updateCaseBid.errorMessage = null;
      state.updateCaseBid.loading = false;
      state.updateCaseBid.success = false;
    },
  },
});
export const getAllBids =
  (
    payload: {
      page: number;
      pageSize: number;
      search?: string;
      sortOrder?: string;
      select?: string;
      role?: string;
      userId?: string;
      searchByCase?: string;
      status?: string;
      sortField?: string;
      caseId?: string;
      currentStatus?: string;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllBidsStart());
      const {
        page,
        pageSize,
        search,
        select,
        sortOrder,
        role,
        userId,
        searchByCase,
        status,
        sortField,
        caseId,
        currentStatus,
      } = payload;
      const response = await APIClient.get("/bids", {
        params: {
          page: page,
          pageSize: pageSize,
          search,
          select,
          sortOrder,
          role,
          userId,
          searchByCase,
          status,
          sortField,
          caseId,
          currentStatus,
        },
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllBidsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllBidsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllBidsFail, language);
    }
  };
export const clearBids = () => async (dispatch: AppDispatch) => {
  dispatch(getAllBidsReset());
};
export const getLatestBids =
  (
    payload: { page: number; pageSize: number; sortOrder?: string },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getLatestBidsStart());
      const { page, pageSize, sortOrder } = payload;
      const response = await APIClient.get("/activities", {
        params: {
          page: page,
          pageSize: pageSize,
          sortOrder,
        },
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getLatestBidsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getLatestBidsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getLatestBidsFail, language);
    }
  };
export const clearLatestBids = () => async (dispatch: AppDispatch) => {
  dispatch(getLatestBidsReset());
};
export const getOneBid =
  (payload: { id: string; stages?: string }, options: { language: string }) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getOneBidStart());

      const { id } = payload;
      const response = await APIClient.get(`/bids/${id}`);
      if (
        response.data
        // && response.data.success
      ) {
        const data = response.data;
        dispatch(getOneBidSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneBidFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneBidFail, language);
    }
  };
export const clearOneBid = () => async (dispatch: AppDispatch) => {
  dispatch(getOneBidReset());
};
export const getBidStats =
  (payload: { id: string }, options: { language: string }) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getBidStatsStart());

      const { id } = payload;
      const response = await APIClient.get(`/dashboard/bids-stats/${id}`);
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getBidStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getBidStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getBidStatsFail, language);
    }
  };
export const clearBidStats = () => async (dispatch: AppDispatch) => {
  dispatch(getBidStatsReset());
};
export const createBid =
  (
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      step?: number;
      handleStep?: (step: number) => void;
      updateState?: (value: string) => void;
      recall?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      step,
      handleStep,
      updateState,
      recall,
    } = options;
    try {
      dispatch(createBidStart());

      const response = await APIClient.post(`/bids`, payload);
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(createBidSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (step && handleStep) handleStep(step);
        if (updateState) updateState(data.id);
        if (recall) recall();
      } else if (response && response.data && response.data.errors) {
        dispatch(createBidFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createBidFail, language);
    }
  };
export const clearCreateBid = () => async (dispatch: AppDispatch) => {
  dispatch(createBidReset());
};
export const updateBid =
  (
    id: string,
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      handleStep?: () => void;
      shouldNavigate?: boolean;
      pathname?: string;
      navigate?: (path: string) => void;
      recall?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      handleStep,
      shouldNavigate,
      pathname,
      navigate,
      recall,
    } = options;
    try {
      dispatch(updateBidStart());

      const response = await APIClient.patch(`/bids/${id}`, payload);
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(updateBidSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (shouldNavigate && pathname && navigate) navigate(pathname);
        if (handleStep) handleStep();
        if (recall) recall();
      } else if (response && response.data && response.data.errors) {
        dispatch(updateBidFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateBidFail, language);
    }
  };
export const clearUpdateBid = () => async (dispatch: AppDispatch) => {
  dispatch(updateBidReset());
};
export const updateCaseBid =
  (
    id: string,
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      handleStep?: () => void;
      shouldNavigate?: boolean;
      pathname?: string;
      navigate?: (path: string, options?: { state: { stage: number } }) => void;
      recall?: () => void;
      navigateState?: { state: { stage: number } };
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      handleStep,
      shouldNavigate,
      pathname,
      navigate,
      recall,
      navigateState,
    } = options;
    try {
      dispatch(updateCaseBidStart());

      const response = await APIClient.patch(
        `/bids/case-bid-update/${id}`,
        payload
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(updateCaseBidSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (shouldNavigate && pathname && navigate)
          navigate(pathname, navigateState);
        if (handleStep) handleStep();
        if (recall) recall();
      } else if (response && response.data && response.data.errors) {
        dispatch(updateCaseBidFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateCaseBidFail, language);
    }
  };
export const clearUpdateCaseBid = () => async (dispatch: AppDispatch) => {
  dispatch(updateCaseBidReset());
};

const {
  getAllBidsFail,
  getAllBidsStart,
  getAllBidsSuccess,
  getAllBidsReset,
  getLatestBidsFail,
  getLatestBidsStart,
  getLatestBidsSuccess,
  getLatestBidsReset,
  getOneBidFail,
  getOneBidStart,
  getOneBidSuccess,
  getOneBidReset,
  getBidStatsFail,
  getBidStatsStart,
  getBidStatsSuccess,
  getBidStatsReset,
  createBidFail,
  createBidStart,
  createBidSuccess,
  createBidReset,
  updateBidFail,
  updateBidStart,
  updateBidSuccess,
  updateBidReset,
  updateCaseBidFail,
  updateCaseBidStart,
  updateCaseBidSuccess,
  updateCaseBidReset,
} = bidsSlice.actions;
export default bidsSlice.reducer;
