import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import APIClient from "../../services/ApiClient";
import { Notification } from "../../utils/Notification";
import { AppDispatch } from "..";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const countrySlice = createSlice({
  name: "countries",
  initialState: {
    getAll: { ...initialState },
    getOne: { ...initialState },
    create: { ...initialState },
    update: { ...initialState },
    delete: { ...initialState },
  },
  reducers: {
    getAllCountriesStart(state) {
      state.getAll.data = null;
      state.getAll.loading = true;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.success = false;
    },
    getAllCountriesSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.loading = false;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.success = true;
    },
    getAllCountriesFail(state, action: PayloadAction<string>) {
      state.getAll.data = null;
      state.getAll.error = true;
      state.getAll.errorMessage = action.payload;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllCountriesReset(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneCountryStart(state) {
      state.getOne.data = null;
      state.getOne.loading = true;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.success = false;
    },
    getOneCountrySuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.loading = false;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.success = true;
    },
    getOneCountryFail(state, action: PayloadAction<string>) {
      state.getOne.data = null;
      state.getOne.error = true;
      state.getOne.errorMessage = action.payload;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneCountryReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    createCountryStart(state) {
      state.create.data = null;
      state.create.loading = true;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.success = false;
    },
    createCountriesSuccess(state, action) {
      state.create.data = action.payload;
      state.create.loading = false;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.success = true;
    },
    createCountriesFail(state, action: PayloadAction<string>) {
      state.create.data = null;
      state.create.error = true;
      state.create.errorMessage = action.payload;
      state.create.loading = false;
      state.create.success = false;
    },
    createCountriesReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateCountryStart(state) {
      state.update.loading = true;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.success = false;
    },
    updateCountriesSuccess(state, action) {
      state.update.data = { docs: [action.payload], totalDocs: 1 };
      state.update.loading = false;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.success = true;
    },
    updateCountriesFail(state, action: PayloadAction<string | undefined>) {
      state.update.error = true;
      state.update.errorMessage = action.payload || null;
      state.update.loading = false;
      state.update.success = false;
    },
    updateCountriesReset(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = false;
    },
    deleteCountriesStart(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = true;
      state.delete.success = false;
    },
    deleteCountriesSuccess(state, action) {
      state.delete.data = action.payload;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = true;
    },
    deleteCountriesFail(state, action) {
      state.delete.data = null;
      state.delete.errorMessage = action.payload;
      state.delete.error = true;
      state.delete.loading = false;
      state.delete.success = false;
    },
    deleteCountriesReset(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = false;
    },
  },
});
export const getAllCountries =
  (
    payload: {
      page?: number;
      pageSize?: number;
      pagination?: boolean;
      regionIds?: string;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllCountriesStart());
      const response = await APIClient.get("/countries", { params: payload });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllCountriesSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllCountriesFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllCountriesFail, language);
    }
  };
export const clearCountries = () => async (dispatch: AppDispatch) => {
  dispatch(getAllCountriesReset());
};
export const getOneCountry =
  (payload: { countrieId: string }, options: { language: string }) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getOneCountryStart());
      const { countrieId } = payload;
      const response = await APIClient.get(`/countries/${countrieId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getOneCountrySuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneCountryFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneCountryFail, language);
    }
  };
export const clearCountry = () => async (dispatch: AppDispatch) => {
  dispatch(getOneCountryReset());
};
export const createCountries =
  (
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(createCountryStart());
      const response = await APIClient.post("/countries", payload);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(createCountriesSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(createCountriesFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createCountriesFail, language);
    }
  };
export const clearCreateCountries = () => async (dispatch: AppDispatch) => {
  dispatch(createCountriesReset());
};
export const updateCountries =
  (
    countrieId: string,
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(updateCountryStart());
      const response = await APIClient.patch(
        `/countries/${countrieId}`,
        payload
      );
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(updateCountriesSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(updateCountriesFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateCountriesFail, language);
    }
  };
export const clearUpdateCountries = () => async (dispatch: AppDispatch) => {
  dispatch(updateCountriesReset());
};
export const deleteCountries =
  (payload: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(deleteCountriesStart());
      const { countriesId } = payload;
      const response = await APIClient.patch(`/countries/${countriesId}`, {
        isActive: false,
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(deleteCountriesSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(deleteCountriesFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      if (
        error &&
        error.response?.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "string"
      ) {
        dispatch(deleteCountriesFail(error.response.data.errors));
        Notification("error", error.response.data.errors);
      } else if (
        Object.prototype.toString.call(error.response.data.errors) ===
        "[object Object]"
      ) {
        const message = Object.keys(error.response.data.errors)
          .map((key) => error.response.data.errors[key])
          .join(".\n");

        dispatch(deleteCountriesFail(message));
        Notification("error", message);
      } else {
        dispatch(deleteCountriesFail(error.message));
        Notification("error", error.message);
      }
    }
  };
export const clearDeleteCountries = () => async (dispatch: AppDispatch) => {
  dispatch(deleteCountriesReset());
};

export const {
  getAllCountriesStart,
  getAllCountriesSuccess,
  getAllCountriesFail,
  getAllCountriesReset,
  getOneCountryStart,
  getOneCountrySuccess,
  getOneCountryFail,
  getOneCountryReset,
  createCountryStart,
  createCountriesSuccess,
  createCountriesFail,
  createCountriesReset,
  updateCountryStart,
  updateCountriesSuccess,
  updateCountriesFail,
  updateCountriesReset,
  deleteCountriesStart,
  deleteCountriesSuccess,
  deleteCountriesFail,
  deleteCountriesReset,
} = countrySlice.actions;

export default countrySlice.reducer;
