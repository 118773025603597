import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";

const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: "en",
    direction: "ltr",
  },
  reducers: {
    changeLanguageState(state, action) {
      state.language = action.payload;
    },
    changeDirectionState(state, action) {
      state.direction = action.payload;
    },
  },
});
export const changeLanguage =
  (language: string) => async (dispatch: AppDispatch) => {
    dispatch(changeLanguageState(language));
  };
export const changeDirection =
  (direction: string) => async (dispatch: AppDispatch) => {
    dispatch(changeDirectionState(direction));
  };

const { changeLanguageState, changeDirectionState } = languageSlice.actions;
export default languageSlice.reducer;
