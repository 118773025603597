import {
  // AppstoreOutlined,
  UserAddOutlined,
  HomeOutlined,
  ProfileOutlined,
  StarOutlined,
  SettingOutlined,
  FlagOutlined,
  UngroupOutlined,
  HeatMapOutlined,
  EuroOutlined,
  DeploymentUnitOutlined,
  NodeIndexOutlined,
  QuestionOutlined,
  UserOutlined,
  SaveOutlined,
  ShoppingOutlined,
  BankOutlined,
  FolderOpenOutlined,
  BookOutlined,
  DollarOutlined,
  FileOutlined,
  CloseSquareOutlined,
  ApiOutlined,
  BackwardOutlined,
  AccountBookOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import { ReactNode } from "react";

interface ItemType {
  key: string;
  icon?: ReactNode;
  children?: ItemType[];
  label?: ReactNode;
}

function getItem(
  label: string,
  key: string,
  icon?: ReactNode | null,
  children?: ItemType[]
): ItemType {
  return {
    key,
    icon,
    children,
    label,
  };
}
export const admin = [
  getItem("LexHub", "/lexhub", <AccountBookOutlined />),
  getItem("Dashboard", "/dashboard", <HomeOutlined />),
  getItem("User Leads", "/dashboard/user-leads", <UserAddOutlined />),
  getItem("LexConnect", "/dashboard/lexconnect", <ApiOutlined />),
  getItem("Featured Members", "/dashboard/featured-members", <StarOutlined />),
  getItem("Case Activity", "/dashboard/cases", <ProfileOutlined />),
  getItem("Case Bids", "/dashboard/case-bids", <NodeIndexOutlined />),
  getItem("Old Cases", "/dashboard/old-cases", <BackwardOutlined />),
  getItem(
    "Lexolent Cases",
    "/dashboard/lexolent-cases",
    <FolderOpenOutlined />
  ),
  getItem("LexTrade Cases", "/dashboard/house-fund-cases", <BankOutlined />),
  getItem(
    "Marketplace Cases",
    "/dashboard/marketplace-cases",
    <ShoppingOutlined />
  ),
  getItem("Settings", "sub1", <SettingOutlined />, [
    // getItem("Tags", "/dashboard/tags", <AppstoreOutlined />),
    getItem("Regions", "/dashboard/regions", <DeploymentUnitOutlined />),
    getItem(
      "Claim Jurisdictions",
      "/dashboard/claim-jurisdictions",
      <FlagOutlined />
    ),
    getItem("Case Types", "/dashboard/case-types", <UngroupOutlined />),
    getItem("Claim Types", "/dashboard/claim-types", <HeatMapOutlined />),
    getItem("Currency", "/dashboard/currency", <EuroOutlined />),
    getItem("Forum", "/dashboard/forums", <NodeIndexOutlined />),
    getItem("Specializations", "/dashboard/specializations", <BookOutlined />),
  ]),
];

export const networkMember = [
  getItem("LexHub", "/lexhub", <AccountBookOutlined />),
  getItem("Dashboard", "/dashboard", <HomeOutlined />),
  getItem("Profile", "/dashboard/edit-profile", <UserOutlined />),
  getItem("Cases", "/dashboard/cases", <ProfileOutlined />),
  getItem("Referred Cases", "/dashboard/cases-referred", <FileOutlined />),
  getItem("Estimated Fee", "/dashboard/estimated-fee", <DollarOutlined />),
  getItem("LexConnect", "/dashboard/lexconnect", <ApiOutlined />),
  getItem("Featured Members", "/dashboard/featured-members", <StarOutlined />),
  getItem("FAQ", "/dashboard/FAQ", <QuestionOutlined />),
];

export const investorMember = [
  getItem("LexHub", "/lexhub", <AccountBookOutlined />),
  getItem("Dashboard", "/dashboard", <HomeOutlined />),
  getItem("Profile", "/dashboard/edit-profile", <UserOutlined />),
  getItem("New Cases", "/dashboard/new-cases", <BulbOutlined />),
  getItem("My Bids", "/dashboard/my-bids", <ProfileOutlined />),
  getItem("Funded Cases", "/dashboard/funded-cases", <DollarOutlined />),
  getItem(
    "Declined Cases",
    "/dashboard/declined-cases",
    <CloseSquareOutlined />
  ),
  getItem("Saved Cases", "/dashboard/saved-cases", <SaveOutlined />),
  getItem("LexConnect", "/dashboard/lexconnect", <ApiOutlined />),
  getItem("Featured Members", "/dashboard/featured-members", <StarOutlined />),
  getItem("FAQ", "/dashboard/FAQ", <QuestionOutlined />),
];

export const associateMember = [
  getItem("LexHub", "/lexhub", <AccountBookOutlined />),
  getItem("Dashboard", "/dashboard", <HomeOutlined />),
  getItem("Profile", "/dashboard/edit-profile", <UserOutlined />),
  getItem("Cases", "/dashboard/cases", <ProfileOutlined />),
  getItem("LexConnect", "/dashboard/lexconnect", <ApiOutlined />),
  getItem("Featured Members", "/dashboard/featured-members", <StarOutlined />),
  getItem("LexRecruit", "/dashboard/lexrecruit", <BookOutlined />),
  getItem("FAQ", "/dashboard/FAQ", <QuestionOutlined />),
];

export const networkIntroducer = [
  getItem("LexHub", "/lexhub", <AccountBookOutlined />),
  getItem("Dashboard", "/dashboard", <HomeOutlined />),
  getItem("Profile", "/dashboard/edit-profile", <UserOutlined />),
  getItem("Cases", "/dashboard/cases", <ProfileOutlined />),
  getItem("Estimated Fee", "/dashboard/estimated-fee", <DollarOutlined />),
  getItem("Featured Members", "/dashboard/featured-members", <StarOutlined />),
  getItem("LexConnect", "/dashboard/lexconnect", <ApiOutlined />),
  getItem("LexLearn", "/dashboard/lexlearn", <BookOutlined />),
  getItem("FAQ", "/dashboard/FAQ", <QuestionOutlined />),
];
