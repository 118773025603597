import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
// import { getTags } from "../tags";
import { getClaimTypes } from "../claimtype";
import { getAllJurisdictionalRegion } from "../jurisdictionRegion";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState: {
    getAll: { ...initialState },
    pendingValue: 0,
    all: 0,
    getOne: { ...initialState },
    getStats: { ...initialState },
    getFeatured: { ...initialState },
    getDirectory: { ...initialState },
    getNetworkMembers: { ...initialState },
    getEmailExists: { ...initialState },
    updateOne: { ...initialState },
    uploadPhoto: { ...initialState },
    uploadLogo: { ...initialState },
  },
  reducers: {
    updateAll(state, action) {
      state.all = action.payload;
    },
    updatePendingValue(state, action) {
      state.pendingValue = action.payload;
    },
    getAllUsersStart(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = true;
      state.getAll.success = false;
    },
    getAllUsersSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = true;
    },
    getAllUsersFail(state, action) {
      state.getAll.data = null;
      state.getAll.errorMessage = action.payload;
      state.getAll.error = true;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllUsersReset(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneUserStart(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = true;
      state.getOne.success = false;
    },
    getOneUserSuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = true;
    },
    getOneUserFail(state, action) {
      state.getOne.data = null;
      state.getOne.errorMessage = action.payload;
      state.getOne.error = true;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneUserReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getUserStatsStart(state) {
      state.getStats.data = null;
      state.getStats.error = false;
      state.getStats.errorMessage = null;
      state.getStats.loading = true;
      state.getStats.success = false;
    },
    getUserStatsSuccess(state, action) {
      state.getStats.data = action.payload;
      state.getStats.error = false;
      state.getStats.errorMessage = null;
      state.getStats.loading = false;
      state.getStats.success = true;
    },
    getUserStatsFail(state, action) {
      state.getStats.data = null;
      state.getStats.errorMessage = action.payload;
      state.getStats.error = true;
      state.getStats.loading = false;
      state.getStats.success = false;
    },
    getUserStatsReset(state) {
      state.getStats.data = null;
      state.getStats.error = false;
      state.getStats.errorMessage = null;
      state.getStats.loading = false;
      state.getStats.success = false;
    },
    getFeaturedUsersStart(state) {
      state.getFeatured.data = null;
      state.getFeatured.error = false;
      state.getFeatured.errorMessage = null;
      state.getFeatured.loading = true;
      state.getFeatured.success = false;
    },
    getFeaturedUsersSuccess(state, action) {
      state.getFeatured.data = action.payload;
      state.getFeatured.error = false;
      state.getFeatured.errorMessage = null;
      state.getFeatured.loading = false;
      state.getFeatured.success = true;
    },
    getFeaturedUsersFail(state, action) {
      state.getFeatured.data = null;
      state.getFeatured.errorMessage = action.payload;
      state.getFeatured.error = true;
      state.getFeatured.loading = false;
      state.getFeatured.success = false;
    },
    getFeaturedUsersReset(state) {
      state.getFeatured.data = null;
      state.getFeatured.error = false;
      state.getFeatured.errorMessage = null;
      state.getFeatured.loading = false;
      state.getFeatured.success = false;
    },
    getMembersDirectoryStart(state) {
      state.getDirectory.data = null;
      state.getDirectory.error = false;
      state.getDirectory.errorMessage = null;
      state.getDirectory.loading = true;
      state.getDirectory.success = false;
    },
    getMembersDirectorySuccess(state, action) {
      state.getDirectory.data = action.payload;
      state.getDirectory.error = false;
      state.getDirectory.errorMessage = null;
      state.getDirectory.loading = false;
      state.getDirectory.success = true;
    },
    getMembersDirectoryFail(state, action) {
      state.getDirectory.data = null;
      state.getDirectory.errorMessage = action.payload;
      state.getDirectory.error = true;
      state.getDirectory.loading = false;
      state.getDirectory.success = false;
    },
    getMembersDirectoryReset(state) {
      state.getDirectory.data = null;
      state.getDirectory.error = false;
      state.getDirectory.errorMessage = null;
      state.getDirectory.loading = false;
      state.getDirectory.success = false;
    },
    getNetworkMembersStart(state) {
      state.getNetworkMembers.data = null;
      state.getNetworkMembers.error = false;
      state.getNetworkMembers.errorMessage = null;
      state.getNetworkMembers.loading = true;
      state.getNetworkMembers.success = false;
    },
    getNetworkMembersSuccess(state, action) {
      state.getNetworkMembers.data = action.payload;
      state.getNetworkMembers.error = false;
      state.getNetworkMembers.errorMessage = null;
      state.getNetworkMembers.loading = false;
      state.getNetworkMembers.success = true;
    },
    getNetworkMembersFail(state, action) {
      state.getNetworkMembers.data = null;
      state.getNetworkMembers.errorMessage = action.payload;
      state.getNetworkMembers.error = true;
      state.getNetworkMembers.loading = false;
      state.getNetworkMembers.success = false;
    },
    getNetworkMembersReset(state) {
      state.getNetworkMembers.data = null;
      state.getNetworkMembers.error = false;
      state.getNetworkMembers.errorMessage = null;
      state.getNetworkMembers.loading = false;
      state.getNetworkMembers.success = false;
    },
    getEmailExistsStart(state) {
      state.getEmailExists.data = null;
      state.getEmailExists.error = false;
      state.getEmailExists.errorMessage = null;
      state.getEmailExists.loading = true;
      state.getEmailExists.success = false;
    },
    getEmailExistsSuccess(state, action) {
      state.getEmailExists.data = action.payload;
      state.getEmailExists.error = false;
      state.getEmailExists.errorMessage = null;
      state.getEmailExists.loading = false;
      state.getEmailExists.success = true;
    },
    getEmailExistsFail(state, action) {
      state.getEmailExists.data = null;
      state.getEmailExists.errorMessage = action.payload;
      state.getEmailExists.error = true;
      state.getEmailExists.loading = false;
      state.getEmailExists.success = false;
    },
    getEmailExistsReset(state) {
      state.getEmailExists.data = null;
      state.getEmailExists.error = false;
      state.getEmailExists.errorMessage = null;
      state.getEmailExists.loading = false;
      state.getEmailExists.success = false;
    },
    updateUserStart(state) {
      state.updateOne.data = null;
      state.updateOne.error = false;
      state.updateOne.errorMessage = null;
      state.updateOne.loading = true;
      state.updateOne.success = false;
    },
    updateUserSuccess(state, action) {
      state.updateOne.data = action.payload;
      state.updateOne.error = false;
      state.updateOne.errorMessage = null;
      state.updateOne.loading = false;
      state.updateOne.success = true;
    },
    updateUserFail(state, action) {
      state.updateOne.data = null;
      state.updateOne.errorMessage = action.payload;
      state.updateOne.error = true;
      state.updateOne.loading = false;
      state.updateOne.success = false;
    },
    updateUserReset(state) {
      state.updateOne.data = null;
      state.updateOne.error = false;
      state.updateOne.errorMessage = null;
      state.updateOne.loading = false;
      state.updateOne.success = false;
    },
    uploadPhotoStart(state) {
      state.uploadPhoto.data = null;
      state.uploadPhoto.error = false;
      state.uploadPhoto.errorMessage = null;
      state.uploadPhoto.loading = true;
      state.uploadPhoto.success = false;
    },
    uploadPhotoSuccess(state, action) {
      state.uploadPhoto.data = action.payload;
      state.uploadPhoto.error = false;
      state.uploadPhoto.errorMessage = null;
      state.uploadPhoto.loading = false;
      state.uploadPhoto.success = true;
    },
    uploadPhotoFail(state, action) {
      state.uploadPhoto.data = null;
      state.uploadPhoto.errorMessage = action.payload;
      state.uploadPhoto.error = true;
      state.uploadPhoto.loading = false;
      state.uploadPhoto.success = false;
    },
    uploadPhotoReset(state) {
      state.uploadPhoto.data = null;
      state.uploadPhoto.error = false;
      state.uploadPhoto.errorMessage = null;
      state.uploadPhoto.loading = false;
      state.uploadPhoto.success = false;
    },
    uploadLogoStart(state) {
      state.uploadLogo.data = null;
      state.uploadLogo.error = false;
      state.uploadLogo.errorMessage = null;
      state.uploadLogo.loading = true;
      state.uploadLogo.success = false;
    },
    uploadLogoSuccess(state, action) {
      state.uploadLogo.data = action.payload;
      state.uploadLogo.error = false;
      state.uploadLogo.errorMessage = null;
      state.uploadLogo.loading = false;
      state.uploadLogo.success = true;
    },
    uploadLogoFail(state, action) {
      state.uploadLogo.data = null;
      state.uploadLogo.errorMessage = action.payload;
      state.uploadLogo.error = true;
      state.uploadLogo.loading = false;
      state.uploadLogo.success = false;
    },
    uploadLogoReset(state) {
      state.uploadLogo.data = null;
      state.uploadLogo.error = false;
      state.uploadLogo.errorMessage = null;
      state.uploadLogo.loading = false;
      state.uploadLogo.success = false;
    },
  },
});
export const getAllUsers =
  (
    payload: {
      page?: number;
      pageSize?: number;
      pagination?: boolean;
      search?: string;
      sortOrder?: string;
      select?: string;
      registrationStatus?: string;
      isActive?: boolean;
      role?: string;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllUsersStart());
      const {
        page,
        pageSize,
        pagination,
        search,
        select,
        sortOrder,
        registrationStatus,
        isActive,
        role,
      } = payload;
      const response = await APIClient.get("/users", {
        params: {
          page: page,
          pageSize: pageSize,
          pagination,
          search,
          select,
          sortOrder,
          registrationStatus,
          isActive,
          role,
        },
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllUsersSuccess(data));
        if (registrationStatus === "PENDING") {
          dispatch(updatePendingValue(data.total));
        } else if (!registrationStatus) {
          dispatch(updateAll(data.total));
        }
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllUsersFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllUsersFail, language);
    }
  };
export const clearUsers = () => async (dispatch: AppDispatch) => {
  dispatch(getAllUsersReset());
};
export const getNetworkMembers =
  (
    payload: {
      page?: number;
      pageSize?: number;
      pagination?: boolean;
      search?: string;
      sortOrder?: string;
      select?: string;
      registrationStatus?: string;
      isActive?: boolean;
      role?: string;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getNetworkMembersStart());
      const {
        page,
        pageSize,
        pagination,
        search,
        select,
        sortOrder,
        registrationStatus,
        isActive,
        role,
      } = payload;
      const response = await APIClient.get("/users", {
        params: {
          page: page,
          pageSize: pageSize,
          pagination,
          search,
          select,
          sortOrder,
          registrationStatus,
          isActive,
          role,
        },
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getNetworkMembersSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getNetworkMembersFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getNetworkMembersFail, language);
    }
  };
export const clearNetworkMembers = () => async (dispatch: AppDispatch) => {
  dispatch(getNetworkMembersReset());
};
export const getEmailExists =
  (
    payload: {
      email: string;
    },
    options: {
      language: string;
      handleSuccess: () => void;
      onFailMessage: string;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, handleSuccess, onFailMessage } = options;
    try {
      dispatch(getEmailExistsStart());
      const { email } = payload;
      const response = await APIClient.get("/users/check-email", {
        params: {
          email: email.toLowerCase(),
        },
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getEmailExistsSuccess(data));
        if (data?.isEmailTaken === true) Notification("error", onFailMessage);
        else if (data?.isEmailTaken === false) handleSuccess();
      } else if (response && response.data && response.data.errors) {
        dispatch(getEmailExistsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getEmailExistsFail, language);
    }
  };
export const clearEmailExists = () => async (dispatch: AppDispatch) => {
  dispatch(getEmailExistsReset());
};
export const getWaitingCounter =
  (payload: { page: number; pageSize: number }) =>
  async (dispatch: AppDispatch) => {
    try {
      const { page, pageSize } = payload;
      const response = await APIClient.get("/users", {
        params: {
          page: page,
          pageSize: pageSize,
          registrationStatus: "PENDING",
        },
      });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(updatePendingValue(data.total));
      } else if (response && response.data && response.data.errors) {
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      if (
        error &&
        error.response?.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "string"
      ) {
        Notification("error", error.response.data.errors);
      } else {
        Notification("error", error.message);
      }
    }
  };
export const getOneUser =
  (
    payload: { id: string },
    options: { language: string; fetchData?: boolean }
  ) =>
  async (dispatch: AppDispatch) => {
    let fetchData = false;
    if (options) {
      fetchData = options?.fetchData ? true : false;
    }
    const { language } = options;
    try {
      dispatch(getOneUserStart());

      const { id } = payload;
      const response = await APIClient.get(`/users/${id}`);
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getOneUserSuccess(data));
        if (fetchData) {
          // dispatch(
          //   getTags(
          //     {
          //       pagination: false,
          //     },
          //     { language }
          //   )
          // );
          dispatch(getClaimTypes({ pagination: false }, { language }));
          dispatch(
            getAllJurisdictionalRegion({ pagination: false }, { language })
          );
        }
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneUserFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneUserFail, language);
    }
  };
export const clearOneUser = () => async (dispatch: AppDispatch) => {
  dispatch(getOneUserReset());
};
export const getUserStats =
  (options: { language: string }) => async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getUserStatsStart());

      const response = await APIClient.get("/users/stats");
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getUserStatsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getUserStatsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getUserStatsFail, language);
    }
  };
export const clearUserStats = () => async (dispatch: AppDispatch) => {
  dispatch(getUserStatsReset());
};
export const getFeaturedUsers =
  (
    payload: { page: number; pageSize: number; sortOrder?: string },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getFeaturedUsersStart());

      const { page, pageSize, sortOrder } = payload;
      const response = await APIClient.get("/featured-members", {
        params: { page, pageSize, sortOrder },
      });
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getFeaturedUsersSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getFeaturedUsersFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getFeaturedUsersFail, language);
    }
  };
export const clearFeaturedMembers = () => async (dispatch: AppDispatch) => {
  dispatch(getFeaturedUsersReset());
};
export const getMembersDirectory =
  (
    payload: {
      page: number;
      pageSize: number;
      sortOrder: string;
      role?: string;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getMembersDirectoryStart());

      const { page, pageSize, sortOrder, role } = payload;
      const response = await APIClient.get("/users/member-directory", {
        params: { page, pageSize, sortOrder, role },
      });
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(getMembersDirectorySuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getMembersDirectoryFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getMembersDirectoryFail, language);
    }
  };
export const clearMembersDirectory = () => async (dispatch: AppDispatch) => {
  dispatch(getMembersDirectoryReset());
};
export const updateUser =
  (
    id: string,
    payload: any,
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      recall?: boolean;
      shouldNavigate?: boolean;
      pathname?: string;
      navigate?: (path: string) => void;
      recallFunction?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      recall,
      shouldNavigate,
      navigate,
      pathname,
      recallFunction,
    } = options;
    try {
      dispatch(updateUserStart());

      const response = await APIClient.patch(`/users/${id}`, payload);
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(updateUserSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (recall && recallFunction) recallFunction();
        if (shouldNavigate && navigate && pathname) {
          navigate(pathname);
        }
      } else if (response && response.data && response.data.errors) {
        dispatch(updateUserFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateUserFail, language);
    }
  };
export const clearUpdateUser = () => async (dispatch: AppDispatch) => {
  dispatch(updateUserReset());
};
export const uploadUserPhoto =
  (
    payload: { id: string; formData: any },
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      recall?: boolean;
      shouldNavigate?: boolean;
      pathname?: string;
      navigate?: (path: string) => void;
      recallFunction?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      recall,
      shouldNavigate,
      navigate,
      pathname,
      recallFunction,
    } = options;
    try {
      dispatch(uploadPhotoStart());

      const { id, formData } = payload;
      const response = await APIClient.post(
        `/users/upload-photo/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(uploadPhotoSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (recall && recallFunction) recallFunction();
        if (shouldNavigate && navigate && pathname) {
          navigate(pathname);
        }
      } else if (response && response.data && response.data.errors) {
        dispatch(uploadPhotoFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, uploadPhotoFail, language);
    }
  };
export const clearUploadPhoto = () => async (dispatch: AppDispatch) => {
  dispatch(uploadPhotoReset());
};
export const uploadCompanyLogo =
  (
    payload: { id: string; formData: any },
    options: {
      language: string;
      onSuccessMessage: string;
      showToast: boolean;
      recall?: boolean;
      shouldNavigate?: boolean;
      pathname?: string;
      navigate?: (path: string) => void;
      recallFunction?: () => void;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const {
      language,
      onSuccessMessage,
      showToast,
      recall,
      shouldNavigate,
      navigate,
      pathname,
      recallFunction,
    } = options;
    try {
      dispatch(uploadLogoStart());

      const { id, formData } = payload;
      const response = await APIClient.post(
        `/users/upload-company-photo/${id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.data && response.data.success) {
        const { data } = response.data;
        dispatch(uploadLogoSuccess(data));
        showToast && Notification("success", onSuccessMessage);
        if (recall && recallFunction) recallFunction();
        if (shouldNavigate && navigate && pathname) {
          navigate(pathname);
        }
      } else if (response && response.data && response.data.errors) {
        dispatch(uploadLogoFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, uploadLogoFail, language);
    }
  };
export const clearUploadLogo = () => async (dispatch: AppDispatch) => {
  dispatch(uploadLogoReset());
};

const {
  updateAll,
  updatePendingValue,
  getAllUsersFail,
  getAllUsersStart,
  getAllUsersSuccess,
  getAllUsersReset,
  getOneUserFail,
  getOneUserStart,
  getOneUserSuccess,
  getOneUserReset,
  getUserStatsFail,
  getUserStatsStart,
  getUserStatsSuccess,
  getUserStatsReset,
  getFeaturedUsersFail,
  getFeaturedUsersStart,
  getFeaturedUsersSuccess,
  getFeaturedUsersReset,
  getMembersDirectoryFail,
  getMembersDirectoryStart,
  getMembersDirectorySuccess,
  getMembersDirectoryReset,
  getNetworkMembersFail,
  getNetworkMembersStart,
  getNetworkMembersSuccess,
  getNetworkMembersReset,
  getEmailExistsFail,
  getEmailExistsStart,
  getEmailExistsSuccess,
  getEmailExistsReset,
  updateUserFail,
  updateUserStart,
  updateUserSuccess,
  updateUserReset,
  uploadPhotoFail,
  uploadPhotoStart,
  uploadPhotoSuccess,
  uploadPhotoReset,
  uploadLogoFail,
  uploadLogoStart,
  uploadLogoSuccess,
  uploadLogoReset,
} = usersSlice.actions;
export default usersSlice.reducer;
