import { Layout, Space } from "antd";
import "../../../style/mainlayout.scss";
import { LogoSection } from "./Logo";
import { ProfileSection } from "./Profile";

const { Header } = Layout;

export const HeaderSection = () => {
  return (
    <Header className="app-bar">
      <Space.Compact block direction="horizontal" style={{ gap: "20px" }}>
        <LogoSection />
        <ProfileSection />
      </Space.Compact>
    </Header>
  );
};
