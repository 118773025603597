import APIClient from "../../services/ApiClient";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Notification } from "../../utils/Notification";
import { AuthState } from "../../types/state";
import { HandleAPIError } from "../../utils/Error";

const initialState: AuthState = {
  data: null,
  loading: false,
  error: false,
  errorMessage: null,
  success: false,
};

const tagsSlice = createSlice({
  name: "tags",
  initialState: {
    getAll: { ...initialState },
    getOne: { ...initialState },
    create: { ...initialState },
    update: { ...initialState },
    delete: { ...initialState },
  },
  reducers: {
    getAllTagsStart(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = true;
      state.getAll.success = false;
    },
    getAllTagsSuccess(state, action) {
      state.getAll.data = action.payload;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = true;
    },
    getAllTagsFail(state, action) {
      state.getAll.data = null;
      state.getAll.errorMessage = action.payload;
      state.getAll.error = true;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getAllTagsReset(state) {
      state.getAll.data = null;
      state.getAll.error = false;
      state.getAll.errorMessage = null;
      state.getAll.loading = false;
      state.getAll.success = false;
    },
    getOneTagStart(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = true;
      state.getOne.success = false;
    },
    getOneTagSuccess(state, action) {
      state.getOne.data = action.payload;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = true;
    },
    getOneTagFail(state, action) {
      state.getOne.data = null;
      state.getOne.errorMessage = action.payload;
      state.getOne.error = true;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    getOneTagReset(state) {
      state.getOne.data = null;
      state.getOne.error = false;
      state.getOne.errorMessage = null;
      state.getOne.loading = false;
      state.getOne.success = false;
    },
    createTagStart(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = true;
      state.create.success = false;
    },
    createTagSuccess(state, action) {
      state.create.data = action.payload;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = true;
    },
    createTagFail(state, action) {
      state.create.data = null;
      state.create.errorMessage = action.payload;
      state.create.error = true;
      state.create.loading = false;
      state.create.success = false;
    },
    createTagReset(state) {
      state.create.data = null;
      state.create.error = false;
      state.create.errorMessage = null;
      state.create.loading = false;
      state.create.success = false;
    },
    updateTagStart(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = true;
      state.update.success = false;
    },
    updateTagSuccess(state, action) {
      state.update.data = action.payload;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = true;
    },
    updateTagFail(state, action) {
      state.update.data = null;
      state.update.errorMessage = action.payload;
      state.update.error = true;
      state.update.loading = false;
      state.update.success = false;
    },
    updateTagReset(state) {
      state.update.data = null;
      state.update.error = false;
      state.update.errorMessage = null;
      state.update.loading = false;
      state.update.success = false;
    },
    deleteTagStart(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = true;
      state.delete.success = false;
    },
    deleteTagSuccess(state, action) {
      state.delete.data = action.payload;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = true;
    },
    deleteTagFail(state, action) {
      state.delete.data = null;
      state.delete.errorMessage = action.payload;
      state.delete.error = true;
      state.delete.loading = false;
      state.delete.success = false;
    },
    deleteTagReset(state) {
      state.delete.data = null;
      state.delete.error = false;
      state.delete.errorMessage = null;
      state.delete.loading = false;
      state.delete.success = false;
    },
  },
});
export const getTags =
  (
    payload: {
      page?: number;
      pageSize?: number;
      search?: string;
      sortOrder?: string;
      select?: string;
      pagination?: boolean;
    },
    options: { language: string }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getAllTagsStart());
      const response = await APIClient.get("/tags", { params: payload });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getAllTagsSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getAllTagsFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getAllTagsFail, language);
    }
  };
export const clearTags = () => async (dispatch: AppDispatch) => {
  dispatch(getAllTagsReset());
};
export const getOneTag =
  (payload: { tagId: string }, options: { language: string }) =>
  async (dispatch: AppDispatch) => {
    const { language } = options;
    try {
      dispatch(getOneTagStart());
      const { tagId } = payload;
      const response = await APIClient.get(`/tags/${tagId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(getOneTagSuccess(data));
      } else if (response && response.data && response.data.errors) {
        dispatch(getOneTagFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, getOneTagFail, language);
    }
  };
export const clearTag = () => async (dispatch: AppDispatch) => {
  dispatch(getOneTagReset());
};
export const createTag =
  (
    payload: { name: string; translations: { ar: string } },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(createTagStart());

      const { name, translations } = payload;
      const response = await APIClient.post("/tags", { name, translations });
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(createTagSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(createTagFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, createTagFail, language);
    }
  };
export const clearCreateTag = () => async (dispatch: AppDispatch) => {
  dispatch(createTagReset());
};
export const updateTag =
  (
    payload: {
      tagId: string;
      name?: string;
      isActive?: boolean;
      translations?: { ar: string };
    },
    options: {
      language: string;
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    const { language, recall, onClose, onSuccessMessage } = options;
    try {
      dispatch(updateTagStart());

      const { tagId } = payload;
      const body: any = payload;
      delete body["tagId"];
      const response = await APIClient.patch(`/tags/${tagId}`, body);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(updateTagSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(updateTagFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      HandleAPIError(error, dispatch, updateTagFail, language);
    }
  };
export const clearUpdateTag = () => async (dispatch: AppDispatch) => {
  dispatch(updateTagReset());
};
export const deleteTag =
  (
    payload: { tagId: string },
    options: {
      onSuccessMessage: string;
      recall: (() => void) | undefined;
      onClose: (() => void) | undefined;
    }
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(deleteTagStart());
      const { tagId } = payload;
      const { recall, onClose, onSuccessMessage } = options;
      const response = await APIClient.delete(`/tags/${tagId}`);
      if (response.data && response.data.data) {
        const { data } = response.data;
        dispatch(deleteTagSuccess(data));
        if (onClose) onClose();
        if (recall) recall();
        Notification("success", onSuccessMessage);
      } else if (response && response.data && response.data.errors) {
        dispatch(deleteTagFail(response.data.errors));
        Notification("error", response.data.errors);
      }
    } catch (error: any) {
      if (
        error &&
        error.response?.data &&
        error.response.data.errors &&
        typeof error.response.data.errors === "string"
      ) {
        dispatch(deleteTagFail(error.response.data.errors));
        Notification("error", error.response.data.errors);
      } else if (
        Object.prototype.toString.call(error.response.data.errors) ===
        "[object Object]"
      ) {
        const message = Object.keys(error.response.data.errors)
          .map((key) => error.response.data.errors[key])
          .join(".\n");

        dispatch(deleteTagFail(message));
        Notification("error", message);
      } else {
        dispatch(deleteTagFail(error.message));
        Notification("error", error.message);
      }
    }
  };
export const clearDeleteTag = () => async (dispatch: AppDispatch) => {
  dispatch(deleteTagReset());
};
const {
  getAllTagsFail,
  getAllTagsStart,
  getAllTagsSuccess,
  getAllTagsReset,
  getOneTagFail,
  getOneTagStart,
  getOneTagSuccess,
  getOneTagReset,
  createTagFail,
  createTagStart,
  createTagSuccess,
  createTagReset,
  updateTagFail,
  updateTagStart,
  updateTagSuccess,
  updateTagReset,
  deleteTagFail,
  deleteTagStart,
  deleteTagSuccess,
  deleteTagReset,
} = tagsSlice.actions;
export default tagsSlice.reducer;
