import { ReactElement, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

type GuardProps = {
  children: ReactElement | null;
};

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  let isLoggedIn = false;

  const token = localStorage.getItem("lexolent-token");
  if (token) {
    const decoded: { exp: any } = jwtDecode(token);

    const expAt = new Date(0);
    expAt.setUTCMilliseconds(decoded.exp * 1000);

    if (new Date(Date.now()) < new Date(expAt)) isLoggedIn = true;
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/", { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

export default AuthGuard;
