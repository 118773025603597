import { Layout, Space, Typography } from "antd";
import { Navigate, Outlet } from "react-router-dom";
import "../style/authentication.scss";
import lexolent from "../assets/images/lexolent.svg";
import { CustomizationButton } from "../components/ui/buttons/Customization";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { rtl } from "../types";
import { useTranslation } from "react-i18next";

const { Sider, Content, Header } = Layout;

// ==============================|| MINIMAL LAYOUT ||============================== //

const AuthLayout = () => {
  const token = localStorage.getItem("lexolent-token");

  const { t } = useTranslation();

  const direction = useSelector(
    ({ language }: RootState) => language.direction
  );

  if (token) return <Navigate to="/dashboard" />;
  return (
    <Layout>
      <Sider className="sider">
        <Space.Compact
          block
          direction="vertical"
          // align="baseline"
          className={`${
            direction === rtl ? "sider-right-gap" : "sider-left-gap"
          } sider-content`}
        >
          <img className="auth-logo" src={lexolent} alt="Lexolent" />
          <Typography.Title className="auth-title">
            {t("pathwayToExcellence")}
          </Typography.Title>
          <Typography.Text className="auth-footer">
            © 2024 Lexolent®
          </Typography.Text>
        </Space.Compact>
      </Sider>
      <Layout className="content-layout">
        <Header className="auth-header">
          <Space.Compact
            block
            direction="horizontal"
            className="header-content"
          >
            <img className="auth-logo" src={lexolent} alt="Lexolent" />
          </Space.Compact>
        </Header>
        <Content
          className={`${direction === rtl ? "right-gap" : "left-gap"} content`}
        >
          <Outlet />
          <CustomizationButton />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AuthLayout;
